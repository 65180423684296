import React from 'react'
import { TextField } from '@mui/material'

type FactoryColdCrankingAmpsInputProps = {
  value: string
  onChange: (value: string) => void
}

export const FactoryColdCrankingAmpsInput = ({
  value,
  onChange,
}: FactoryColdCrankingAmpsInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let intValue = e.target.value.replace(/[^0-9]/g, '')

    if (intValue && parseInt(intValue, 10) > 2000) {
      intValue = '2000'
    }

    onChange(intValue)
  }

  return (
    <TextField
      fullWidth
      label="Factory cold cranking amps"
      type="number"
      value={value}
      onChange={handleChange}
      InputProps={{
        style: { background: '#fff' },
      }}
    />
  )
}
