import { createSlice } from '@reduxjs/toolkit';
import { createOffer, fetchOffers } from '../actions/offer';

const initialState = {
  loading: false,
  offers: null,
  error: null,
  success: false,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Offers
      .addCase(fetchOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.offers = payload;
      })
      .addCase(fetchOffers.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      })
      // Create Offer
      .addCase(createOffer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOffer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.offers = payload;
      })
      .addCase(createOffer.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      });
  },
});

export default offerSlice.reducer;
