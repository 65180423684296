import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { ActionTypes } from '../constants/action-types'

export const updateVehicleAttrs = createAsyncThunk(
  ActionTypes.UPDATE_VEHICLE,
  async (body: any, { rejectWithValue }) => {
    try {
      const data = await apiCall(`provider/vehicle/${body.id}`, 'put', body, undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
