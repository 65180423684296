import { Button, Modal, Typography, Box } from '@mui/material'
import { APP_FONT } from '../../constants/app_font'
import ModalTableHeadShort from '../../pages/dashboard/tables/modals/components/table_head_short'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
}

interface SpecialHandlingModalProps {
  isOpen: boolean
  offer: any
  onClose: () => void
}

export default function SpecialHandlingModal({
  isOpen,
  offer,
  onClose,
}: SpecialHandlingModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="special-handling-modal-title"
      aria-describedby="special-handling-modal-description"
    >
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => onClose()}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            mb: 5,
            mt: 3,
          }}
        >
          <Typography
            id="special-handling-modal-title"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: APP_FONT,
              textAlign: 'left',
              color: '##393A3D',
              mb: 3,
            }}
          >
            Special handling requirement
          </Typography>
          <Typography
            id="special-handling-modal-title"
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              fontFamily: APP_FONT,
              textAlign: 'left',
              color: '#FF6600',
              mb: 4,
              mr: 4,
              ml: 4,
            }}
          >
            {offer.consumer_request.vehicle.special_handlings[0].special_handling.title}
          </Typography>
          <Typography
            id="special-handling-modal-description"
            sx={{
              fontFamily: APP_FONT,
              fontSize: '16px',
              color: '#333',
              mb: 4,
              mr: 4,
              ml: 4,
            }}
          >
            {offer.consumer_request.vehicle.special_handlings[0].special_handling.description}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => onClose()}
              sx={{
                width: '90px',
                height: '36px',
                borderRadius: '20px',
                fontFamily: APP_FONT,
                textTransform: 'none',
              }}
              variant="contained"
              color="warning"
            >
              Got it
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
