import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { FetchServiceRequest } from '../../models/service_request'
import { ActionTypes } from '../constants/action-types'

export const fetchServiceRequests = createAsyncThunk(
  ActionTypes.FETCH_SERVICE_REQUESTS,
  async (params: FetchServiceRequest, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/request/${params.providerId}/new`,
        'get',
        undefined,
        undefined
      )
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchMissedServiceRequests = createAsyncThunk(
  ActionTypes.FETCH_MISSED_SERVICE_REQUESTS,
  async (params: any, { rejectWithValue }) => {
    try {
      const data = await apiCall(`provider/request/${params.providerId}/missed`, 'get', undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const expireSR = createAsyncThunk(
  ActionTypes.EXPIRE_SR,
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await apiCall(`provider/request/expire/${id}`, 'put', undefined, undefined)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
