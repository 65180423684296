import React from 'react'
import styled from 'styled-components'
import { TireBrandSelect } from './TireBrandSelect'
import { TireModelInput } from './TireModelInput'
import { TireWarrantyMilesInput } from './TireWarrantyMilesInput'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'

type TireInfoFields = 'TIRE_BRAND' | 'TIRE_MODEL' | 'WARRANTY'

type TireInfoProps = {
  values: {
    TIRE_BRAND: string
    TIRE_MODEL: string
    WARRANTY: number
  }
  onChange: (field: TireInfoFields, value: string | number) => void
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`

export const TireInfo = ({ values, onChange }: TireInfoProps) => {
  const handleBrandChange = (selectedBrand) => {
    const brandName = typeof selectedBrand === 'object' ? selectedBrand.name : selectedBrand
    onChange('TIRE_BRAND', brandName)
  }

  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          fontSize: '14px',
          marginTop: '16px',
          marginBottom: '16px',
          color: '#393A3D',
          fontWeight: 'bold',
        }}
      >
        Provide the brand, model and warranty of the tires you offer:
      </Typography>
      <Row>
        <div style={{ flex: '1' }}>
          <TireBrandSelect value={values.TIRE_BRAND} onChange={handleBrandChange} />
        </div>
        <div style={{ flex: '1' }}>
          <TireModelInput
            value={values.TIRE_MODEL}
            onChange={(value) => onChange('TIRE_MODEL', value)}
          />
        </div>
        <div style={{ flex: '1' }}>
          <TireWarrantyMilesInput
            value={values.WARRANTY}
            onChange={(value) => onChange('WARRANTY', value)}
          />
        </div>
      </Row>
    </div>
  )
}
