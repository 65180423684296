import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { Account } from '../../models/account'
import { ActionTypes } from '../constants/action-types'
import { LoginResponse } from '../../components/modals/user'

export const forgotPassword = createAsyncThunk(
  ActionTypes.FORGOT_PASSWORD,
  async (creds: Account, { rejectWithValue }) => {
    try {
      const data = await apiCall('provider/auth/forgot-password', 'post', creds)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const resetPassword = createAsyncThunk(
  ActionTypes.RESET_PASSWORD,
  async (creds: any, { rejectWithValue }) => {
    try {
      const data = await apiCall('provider/auth/reset-password', 'post', creds)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updatePassword = createAsyncThunk(
  ActionTypes.UPDATE_PASSWORD,
  async (creds: Account, { rejectWithValue }) => {
    try {
      const data = await apiCall('provider/auth/update-password', 'post', creds)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateStripeID = createAsyncThunk(
  ActionTypes.UPDATE_STRIPE_ID,
  async (creds: any, { rejectWithValue }) => {
    try {
      const data = await apiCall(`provider/branch/${creds.id}/stripe-id`, 'patch', {
        stripe_id: creds.stripe_id,
      })
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const registerUser = createAsyncThunk(
  ActionTypes.REGISTER,
  async (creds: Account, { rejectWithValue }) => {
    try {
      const data = await apiCall('provider/auth/register', 'post', creds)
      localStorage.setItem('userToken', data.userToken)
      localStorage.setItem('userId', data.id)
      localStorage.setItem('cognitoId', data.cognitoId)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const loginUser = createAsyncThunk(
  ActionTypes.LOGIN,
  async (creds: Account, { rejectWithValue }) => {
    try {
      const data = await apiCall<LoginResponse>('provider/auth/login', 'post', creds)
      localStorage.setItem('userToken', data.userToken)
      localStorage.setItem('userId', data.id)
      localStorage.setItem('cognitoId', data.cognito_id)
      localStorage.setItem('timezone', data.branch?.metro_area?.moment_timezone)

      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userIsAuth = createAsyncThunk(ActionTypes.IS_AUTH, async (_, { rejectWithValue }) => {
  try {
    const data = await apiCall<LoginResponse>('provider/auth/is-auth', 'get')
    localStorage.setItem('userId', data.id)
    localStorage.setItem('cognitoId', data.cognito_id)
    localStorage.setItem('timezone', data.branch?.metro_area?.moment_timezone)
    return data
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const logoutUser = createAsyncThunk(
  ActionTypes.LOGOUT,
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await apiCall(`provider/auth/logout`, 'post', undefined, undefined)
      localStorage.removeItem('userToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('cognitoId')
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const fetchLoggedInUser = createAsyncThunk(
  ActionTypes.FETCH_LOGGED_IN_USER,
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await apiCall(`/provider/auth/${id}`, 'get', undefined, undefined)
      localStorage.setItem('branchId', data.id)
      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue({ error: error.response.data.message })
      } else {
        return rejectWithValue({ error: error.message })
      }
    }
  }
)
