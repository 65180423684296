import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from '../../common'

import {
  DeleteOfferServiceImageRequest,
  ListOfferServiceImageRequest,
  ListServicesGroupResponse,
  ListServicesResponse,
  Service,
  UploadOfferServiceImagesRequest,
  UploadOfferServiceImagesResponse,
} from './serviceApi.types'

const flattenServices = (services: Service[]): Record<string, Omit<Service, 'children'>> => {
  const flatServices: Record<string, Omit<Service, 'children'>> = {}

  const flatten = (service: Service) => {
    flatServices[service.id] = { ...service }
    if (service.children) {
      service.children.forEach((child) => flatten(child))
    }
  }

  services.forEach((service) => flatten(service))
  return flatServices
}

export const servicesMapping = (services: Service[]): Record<string, Service> => {
  //   recursive
  const mapping: Record<string, Service> = {}
  const map = (service: Service) => {
    mapping[service.id] = service
    if (service.children) {
      service.children.forEach((child) => map(child))
    }
  }
  services.forEach((service) => map(service))
  return mapping
}

// Define the API endpoints
export const serviceApi = createApi({
  baseQuery: baseQuery(),
  reducerPath: 'serviceApi',
  tagTypes: ['Service', 'RecServiceImages'],
  endpoints: (builder) => ({
    // List all services
    listServices: builder.query<ListServicesResponse, { page?: number; limit?: number }>({
      query: (params) => ({
        url: '/service',
        params,
      }),
    }),
    listServicesTree: builder.query<ListServicesGroupResponse, void>({
      query: () => '/service?group=true',
      // 10 minutes
      keepUnusedDataFor: 600000,
      transformResponse: (
        baseQueryReturnValue: ListServicesGroupResponse
      ): ListServicesGroupResponse & { mapping: Record<string, Service> } => {
        //   create mapping of services as object with id as key. Consider hierarchy of services by children array
        return {
          ...baseQueryReturnValue,
          mapping: servicesMapping(baseQueryReturnValue.items),
        }
      },
    }),
    listServicesByIds: builder.query<Record<string, Omit<Service, 'children'>>, void>({
      query: () => '/service?group=true',
      transformResponse: (
        baseQueryReturnValue: ListServicesGroupResponse
      ): Record<string, Omit<Service, 'children'>> => {
        // Flatten the items structure
        return flattenServices(baseQueryReturnValue.items)
      },
      keepUnusedDataFor: 0,
    }),
    // Get a single service by ID
    getServiceById: builder.query<Service, string>({
      query: (id) => `/service/${id}`,
    }),
    uploadRecServiceImages: builder.mutation<
      UploadOfferServiceImagesResponse,
      UploadOfferServiceImagesRequest
    >({
      query: ({ service_id, offer_id, images }) => {
        const formData = new FormData()
        images.forEach((file) => {
          formData.append('images', file)
        })
        return {
          url: `/provider/offer/${offer_id}/service/${service_id}/image`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
    }),
    listRecServiceImages: builder.query<
      UploadOfferServiceImagesResponse,
      ListOfferServiceImageRequest
    >({
      query: ({ service_id, offer_id }) => ({
        url: `/provider/offer/${offer_id}/service/${service_id}/image`,
      }),
      providesTags: ['RecServiceImages'],
    }),
    deleteRecServiceImage: builder.mutation<void, DeleteOfferServiceImageRequest>({
      query: ({ service_id, offer_id, image_id }) => ({
        url: `/provider/offer/${offer_id}/service/${service_id}/image/${image_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RecServiceImages'],
    }),
  }),
})

export const {
  useListServicesQuery,
  useListServicesTreeQuery,
  useLazyListServicesTreeQuery,
  useListServicesByIdsQuery,
  useLazyListServicesByIdsQuery,
  useGetServiceByIdQuery,
  useLazyGetServiceByIdQuery,
  useUploadRecServiceImagesMutation,
  useListRecServiceImagesQuery,
  useDeleteRecServiceImageMutation,
} = serviceApi
