import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'

export default function CustomerSupportModal(props: any) {
  const { isOpen, setIsOpen } = props

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '36%',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#000',
          }}
        >
          Need help with a customer request?
        </Typography>
        <ul
          style={{
            color: '#393A3D',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '400',
            listStyleType: 'disc',
            paddingLeft: '20px',
          }}
        >
          <li>Customer needs a different service than what they came in for</li>
          <li>Customer needs additional services to what they came in for</li>
          <li>Customer cannot be reached / hasn't picked up their vehicle</li>
          <li>Any other questions you have</li>
        </ul>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontWeight: '400',
            textAlign: 'center',
            color: '#000',
            mt: 2,
          }}
        >
          Call our expert Support team now: <strong>(314) 899-2886</strong>
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: '400',
            textAlign: 'center',
            color: '#000',
            mt: 2,
          }}
        >
          Available from 8:00am - 5:00pm
        </Typography>
        <Box component="span" display="flex" justifyContent="right" mt={3}>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            sx={{ width: 68, height: 30, borderRadius: '20px' }}
            variant="contained"
            color="warning"
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
            >
              OK
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
