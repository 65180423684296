import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../common';
import { ListVehicleBrandsResponse } from "./vehicleApi.types";
import { Pagination } from "../common.types";

// Define the API endpoints
export const vehicleApi = createApi({
  baseQuery: baseQuery(),
  reducerPath: 'vehicleApi',
  tagTypes: ['Service', 'RecServiceImages'],
  endpoints: (builder) => ({
    listVehicleBrands: builder.query<
      ListVehicleBrandsResponse,
      Pagination>({
      query: (params) => ({
        url: '/vehicle-brand',
        params,
      })
    }),
  }),
});

export const {
  useListVehicleBrandsQuery,
  useLazyListVehicleBrandsQuery,
} = vehicleApi;
