import { createSlice } from '@reduxjs/toolkit';
import { updateVehicleAttrs } from '../actions/vehicle';

const initialState = {
  loading: false,
  vehicle: null,
  error: null,
  success: false,
};

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Update Vehicle
      .addCase(updateVehicleAttrs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVehicleAttrs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.vehicle = payload;
      })
      .addCase(updateVehicleAttrs.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      });
  },
});

export default vehicleSlice.reducer;
