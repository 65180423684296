import { configureStore } from '@reduxjs/toolkit'
import accountSettingsReducer from './reducers/accountSettingsSlice'
import offerReducer from './reducers/offerSlice'
import serviceRequestReducer from './reducers/serviceRequestSlice'
import userReducer from './reducers/userSlice'
import vehicleReducer from './reducers/vehicleSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const Store = configureStore({
  reducer: {
    user: userReducer,
    service_request: serviceRequestReducer,
    offer: offerReducer,
    account_settings: accountSettingsReducer,
    vehicle: vehicleReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
