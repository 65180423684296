import { sanitizeNumber } from '../../../../components/helper/helper'
import { Service } from './inspection_upload/inspection_upload'
import { OfferBlockService } from './offer_create/OfferServiceBlock'
import { RecommendedService } from './service.model'

const validateAxlePads = (additionalData: any, frontAxle: boolean, rearAxle: boolean): boolean => {
  if (frontAxle && (!additionalData.LF_PAD || !additionalData.RF_PAD)) {
    return false
  }
  if (rearAxle && (!additionalData.LR_PAD || !additionalData.RR_PAD)) {
    return false
  }
  return true
}

const validateTireData = (
  additionalData: any,
  tirePositions: boolean,
  tireInfo: boolean
): boolean => {
  if (!additionalData) {
    return false
  }

  if (
    tirePositions &&
    (!Array.isArray(additionalData.TIRE_POSITIONS) || additionalData.TIRE_POSITIONS.length === 0)
  ) {
    return false
  }

  if (tireInfo) {
    if (
      !additionalData.TIRE_BRAND ||
      typeof additionalData.TIRE_BRAND !== 'string' ||
      additionalData.TIRE_BRAND.length === 0 ||
      !additionalData.TIRE_MODEL ||
      !additionalData.WARRANTY
    ) {
      return false
    }
  }

  return true
}

const validateCrankingAmps = (additionalData: any, crankingAmps: boolean): boolean => {
  if (
    crankingAmps &&
    (!additionalData.COLD_CRANKING_AMPS ||
      additionalData.COLD_CRANKING_AMPS <= 0 ||
      !additionalData.FACTORY_COLD_CRANKING_AMPS ||
      additionalData.FACTORY_COLD_CRANKING_AMPS <= 0)
  ) {
    return false
  }
  return true
}

const validateParts = (parts: any[]): boolean => {
  return !parts.some((part) => !part.name || part.quantity <= 0 || part.price_per_unit <= 0)
}

export const validateRecommendedServiceFields = (
  services: Service[],
  service: RecommendedService,
  labor_rate: number
): boolean => {
  const additionalData = (service.additional_data as Partial<any>) || {}

  const selectedService =
    service.types.find((s) => s.id === service.type) || services.find((s) => s.id === service.id)

  const recommendedRequiredData = selectedService?.recommend_required_data || []
  const isTirePositions = recommendedRequiredData.includes('TIRE_POSITIONS')
  const isTireInfo = recommendedRequiredData.includes('TIRE_BRAND')
  const isCrankingAmps = recommendedRequiredData.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD')
  const isRearAxle =
    recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD')

  if (
    !validateTireData(additionalData, isTirePositions, isTireInfo) ||
    !validateCrankingAmps(additionalData, isCrankingAmps) ||
    !validateAxlePads(additionalData, isFrontAxle, isRearAxle) ||
    !validateParts(service.parts)
  ) {
    return false
  }

  const imageFilesRequired =
    selectedService?.is_photo_upload_required ||
    sanitizeNumber(service?.labor_hours) * labor_rate > 500
  if (imageFilesRequired && (!service.imageFiles || service.imageFiles.length === 0)) {
    return false
  }

  return true
}

export const validateOfferServiceFields = (
  service: OfferBlockService,
  parentService: any
): boolean => {
  const serviceChild = parentService?.child
  const recommendedRequiredData =
    serviceChild?.recommend_required_data || parentService?.recommend_required_data || []

  const additionalData = (service.additional_data as Partial<any>) || {}

  const isTirePositions = recommendedRequiredData.includes('TIRE_POSITIONS')
  const isTireInfo = recommendedRequiredData.includes('TIRE_BRAND')
  const isCrankingAmps = recommendedRequiredData.includes('COLD_CRANKING_AMPS')
  const isFrontAxle =
    recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD')
  const isRearAxle =
    recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD')

  if (
    !validateTireData(additionalData, isTirePositions, isTireInfo) ||
    !validateCrankingAmps(additionalData, isCrankingAmps) ||
    !validateAxlePads(additionalData, isFrontAxle, isRearAxle) ||
    !validateParts(service.parts)
  ) {
    return false
  }

  return true
}
