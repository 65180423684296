export type Holiday = Date

export const getHolidaysForYear = (year: number): Holiday[] => {
  const holidays: Holiday[] = []

  // New Year's Day
  holidays.push(new Date(year, 0, 1))

  // Memorial Day (last Monday of May)
  const memorialDay: Date = new Date(year, 4, 31)
  while (memorialDay.getDay() !== 1) {
    memorialDay.setDate(memorialDay.getDate() - 1)
  }
  holidays.push(memorialDay)

  // Independence Day
  holidays.push(new Date(year, 6, 4))

  // Labor Day (first Monday of September)
  const laborDay: Date = new Date(year, 8, 1)
  while (laborDay.getDay() !== 1) {
    laborDay.setDate(laborDay.getDate() + 1)
  }
  holidays.push(laborDay)

  // Thanksgiving (fourth Thursday of November)
  const thanksgiving: Date = new Date(year, 10, 1)
  let count: number = 0 // Counter for Thursdays
  while (count < 4) {
    thanksgiving.setDate(thanksgiving.getDate() + 1)
    if (thanksgiving.getDay() === 4) {
      count++
    }
  }
  holidays.push(thanksgiving)

  // Christmas Day
  holidays.push(new Date(year, 11, 25))

  return holidays
}

const currentYear = new Date().getFullYear()
const nextYear = currentYear + 1
const holidaysThisYear = getHolidaysForYear(currentYear)
const holidaysNextYear = getHolidaysForYear(nextYear)
export const holidays = [...holidaysThisYear, ...holidaysNextYear]

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

const addDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const isHoliday = (date: Date, holidays: Holiday[]): boolean => {
  return holidays.some((holiday) => isSameDay(date, holiday))
}

export const calculateAvailableDates = (currentDate: Date) => {
  let additionalDays: number[] = []
  let maxDate = new Date(currentDate)

  switch (currentDate.getDay()) {
    case 5: // Friday
      additionalDays = [0, 1, 2, 3]
      break
    case 6: // Saturday
      additionalDays = [0, 1, 2, 3]
      break
    case 0: // Sunday
      additionalDays = [0, 1, 2]
      break
    default:
      additionalDays = [0, 1] // Other days
  }

  maxDate = addDays(currentDate, Math.max(...additionalDays))

  for (let i = 0; i <= Math.max(...additionalDays); i++) {
    let checkDate: Date = addDays(currentDate, i)
    if (isHoliday(checkDate, holidays)) {
      maxDate = addDays(maxDate, 1)
    }
  }

  return maxDate
}
