import { Box, Card, ClickAwayListener, Grow, Popper } from '@mui/material'
import { useRef, useState } from 'react'

type Props = {
  children: React.ReactNode
  holder: React.ReactNode
}

const HoverPopper = ({ children, holder }: Props) => {
  const [openedPopper, setOpenedPopper] = useState<boolean>(false)
  const [popperTimeout, setPopperTimeout] = useState<any>()
  const popperAnchor = useRef(null)

  const popperEnter = () => {
    clearTimeout(popperTimeout)
    const timeout = setTimeout(() => {
      setOpenedPopper(true)
    }, 300)
    setPopperTimeout(timeout)
  }

  const popperLeave = () => {
    clearTimeout(popperTimeout)
    const timeout = setTimeout(() => {
      setOpenedPopper(false)
    }, 300)
    setPopperTimeout(timeout)
  }

  return (
    <Box
      component="span"
      className="hello"
      ref={popperAnchor}
      aria-haspopup="true"
      onMouseEnter={popperEnter}
      onMouseLeave={popperLeave}
      sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', zIndex: 0 }}
    >
      {holder}

      <ClickAwayListener onClickAway={popperLeave}>
        <Popper
          open={openedPopper}
          anchorEl={popperAnchor.current}
          placement="bottom-start"
          onMouseEnter={popperEnter}
          onMouseLeave={popperLeave}
          transition
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, ...p }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: '0 0 0' }}
              timeout={300}
            >
              <Card sx={{ boxShadow: 3 }}>{children}</Card>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  )
}

export default HoverPopper
