import * as React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Paper,
  IconButton,
  Button,
  styled,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useDispatch } from 'react-redux'
import { fetchOffers, updateOfferStatus } from '../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { UpdateOffer } from '../../../models/offer'
import socket from '../../../sockets/socket'
import VinModal from '../../../components/modals/vin_modal'
import { updateVehicleAttrs } from '../../../redux/actions/vehicle'
import MileageInModal from '../../../components/modals/mileage_in_modal'
import MarkAsArrivedModal from '../../../components/modals/mark_as_arrived_modal'
import MarkAsCompleteModal from '../../../components/modals/mark_as_complete_modal'
import { APP_FONT } from '../../../constants/app_font'
import { ReactComponent as AcceptedOffersIcon } from '../../../assets/images/accepted_offers_icon.svg'
import HoverPopper from '../../../components/hover/hover_popper'
import { formatDateTime } from '../../../utils/helper'
import SetUpPickUpModal from './modals/set_pick_up_modal'
import useLocalTimezoneDates from '../../../utils/localizeTimezone'
import DiagnosticUploadModal from './modals/diagnostic_upload/diagnostic_upload'
import { useState, useEffect, useCallback } from 'react'
import Copy from '../../../assets/images/contentCopy.svg'
import CopyFilled from '../../../assets/images/contentCopyFilled.svg'
import InvoiceUploadModal from './modals/invoice_upload/invoice_upload'
import { Offer } from '../../../models/offer_new'
import SpecialHandlingModal from '../../../components/modals/special_handling'
import RecommendServicesModal from './modals/add_recommended_services'
import { InfoChip } from './modals/components/ui/info_chip'
import { ArrowTooltip } from './modals/components/ui/arrow_tooltip'
import CallCustomerModal from '../../../components/modals/call_customer_modal'
import { useNavigate } from 'react-router-dom'
import InspectionUploadModal, { Service } from './modals/inspection_upload/inspection_upload'
import MileageOutModal from '../../../components/modals/mileage_out_modal'
import { formatPrice } from '../../../components/helper/helper'
import ServiceList from '../components/service_list'

interface Data {
  // AutomatedImg: string
  OrderID: string
  Vehicle: string
  VehicleId: string
  VIN: string
  VehicleClass: string
  EngineType: string
  EngineSize: string
  Services: string
  OfferAmount: string
  TimeBlock: string
  Status: string
  PickUpAction: string
  Comments: string
  SpecialHandling: string
  Action: string
  PickupTime: string
}

const StickyHeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  top: 0,
  zIndex: 10,
  position: 'sticky',
  boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)',
}))

const StyledButton = styled(IconButton)`
  & {
    width: 35px;
    height: 35px;
    margin: 12px;
  }
  ,
  &&:hover {
    width: 35px;
    height: 35px;
    background-color: #fff0eb;
    margin: 12px;
  }
`

const rows = []
type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: 'AutomatedImg',
  //   disablePadding: false,
  //   label: '',
  // },
  {
    id: 'OrderID',
    disablePadding: true,
    label: 'Order ID',
  },
  {
    id: 'Vehicle',
    disablePadding: false,
    label: 'Vehicle',
  },
  {
    id: 'VehicleId',
    disablePadding: false,
    label: 'Vehicle ID',
  },
  {
    id: 'VIN',
    disablePadding: false,
    label: 'VIN',
  },
  {
    id: 'VehicleClass',
    disablePadding: false,
    label: 'Vehicle class',
  },
  {
    id: 'EngineType',
    disablePadding: false,
    label: 'Engine type',
  },
  {
    id: 'EngineSize',
    disablePadding: false,
    label: 'Engine size',
  },
  {
    id: 'Services',
    disablePadding: false,
    label: 'Services',
  },
  {
    id: 'OfferAmount',
    disablePadding: false,
    label: 'Offer amount',
  },
  {
    id: 'TimeBlock',
    disablePadding: false,
    label: 'Drop-off time',
  },
  {
    id: 'PickupTime',
    disablePadding: false,
    label: 'Pick up time',
  },
  {
    id: 'PickUpAction',
    disablePadding: false,
    label: '',
  },
  {
    id: 'Comments',
    disablePadding: false,
    label: 'Comments',
  },
  {
    id: 'SpecialHandling',
    disablePadding: false,
    label: 'Special handling requirements',
  },
  {
    id: 'Status',
    disablePadding: false,
    label: 'Service status',
  },
  {
    id: 'Action',
    disablePadding: false,
    label: 'Action',
  },
]

interface EnhancedTableProps {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StickyHeaderTableCell
            key={headCell.id}
            align={'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <div style={{ fontWeight: '600', fontFamily: APP_FONT }}>{headCell.label}</div>
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </StickyHeaderTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>
    </Toolbar>
  )
}

type Props = {
  formatCurrency: any
  setAlert: Function
}

export default function Accepted(props: Props) {
  const { formatCurrency, setAlert } = props
  const [order] = useState<Order>('asc')
  const [orderBy] = useState<keyof Data>('OrderID')
  const [selected] = useState<readonly string[]>([])
  const [providerId, setProviderId] = useState(localStorage.getItem('branchId'))
  const [currentVehicle, setCurrentVehicle] = useState<any>()
  const [services, setServices] = useState<Service[]>([])
  const [selectedOffer, setSelectedOffer] = useState<any>()
  const [showVinModal, setShowVinModal] = useState(false)
  const [showMileageInModal, setShowMileageInModal] = useState(false)
  const [showMileageOutModal, setShowMileageOutModal] = useState(false)
  const [showMarkAsArrivedModal, setShowMarkAsArrivedModal] = useState(false)
  const [showMarkAsCompletedModal, setShowMarkAsCompletedModal] = useState(false)
  const [showSpecialHandlingModal, setShowSpecialHandlingModal] = useState(false)
  const [showDiagnosticUploadModal, setShowDiagnosticUploadModal] = useState(false)
  const [showInspectionUploadModal, setShowInspectionUploadModal] = useState(false)
  const [showInvoiceUploadModal, setShowInvoiceUploadModal] = useState(false)
  const [selectedOfferId, setSelectedOfferId] = useState<string | null>(null)
  const [acceptedOffers, setAcceptedOffers] = useState<Offer[]>([])
  const [open, setOpen] = useState(false)
  const [copiedVin, setCopiedVin] = useState<string | null>(null)
  const [copiedComment, setCopiedComment] = useState<string | null>(null)
  const [showRecommendMoreModal, setShowRecommendMoreModal] = useState(false)
  const [showCallCustomerModal, setShowCallCustomerModal] = useState(false)
  const [invoiceFile, setinvoiceFile] = useState<File | null>(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const localAcceptedOffers = useLocalTimezoneDates(acceptedOffers, [
    'provider_drop_off_by',
    'drop_off_by',
    'pick_up_after',
  ])

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?group=true&limit=10&page=1`
        )
        const data = await response.json()
        const combinedChildren = data.items?.flatMap((category: any) => category.children) || []
        setServices(combinedChildren)
      } catch (error) {
        console.error('Error fetching services:', error)
      }
    }

    fetchServices()
  }, [])

  const handleCopyVin = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedVin(vin)
        setTimeout(() => {
          setCopiedVin(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyComment = (event: React.MouseEvent, vin: string) => {
    event.stopPropagation()

    navigator.clipboard
      .writeText(vin)
      .then(() => {
        setCopiedComment(vin)
        setTimeout(() => {
          setCopiedComment(null)
        }, 3000)
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const updateVehicle = (vin?: string) => {
    dispatch<any>(
      updateVehicleAttrs({
        id: selectedOffer?.vehicle.id,
        vin: vin,
      })
    )
      .then(unwrapResult)
      .then((vehicle: any) => {
        setCurrentVehicle(vehicle)
        if (selectedOffer.mileage) {
          let new_status
          if (selectedOffer.status.toLowerCase() === 'accepted') {
            if (selectedOffer?.consumer_request.type === 'DIAGNOSTIC') {
              new_status = 'DIAGNOSTIC_IN_PROGRESS'
            } else {
              new_status = 'INSPECTION_IN_PROGRESS'
            }
          } else {
            if (selectedOffer?.consumer_request.type === 'DIAGNOSTIC') {
              new_status = 'PENDING_APPROVAL'
            } else {
              new_status = 'READY_FOR_PICKUP'
            }
          }
          const update_offer: UpdateOffer = {
            id: selectedOffer.id,
            status: new_status,
          }
          dispatch<any>(updateOfferStatus(update_offer))
            .then(unwrapResult)
            .then((updated_offer: any) => {
              setAlert(true)
              navigate(0)
            })
            .catch((err: any) => {
              console.log(err)
            })
        } else {
          setShowMileageInModal(true)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const updateOfferMileage = (mileage?: string) => {
    let new_status
    if (selectedOffer.status.toLowerCase() === 'accepted') {
      if (selectedOffer?.consumer_request.type === 'DIAGNOSTIC') {
        new_status = 'DIAGNOSTIC_IN_PROGRESS'
      } else {
        new_status = 'INSPECTION_IN_PROGRESS'
      }
    } else {
      new_status = 'READY_FOR_PICKUP'
    }
    const update_offer: UpdateOffer = {
      id: selectedOffer.id,
      status: new_status,
      ...(mileage && { in_mileage: parseFloat(mileage.replace(/,/g, '') ?? '0') }),
    }

    dispatch<any>(updateOfferStatus(update_offer))
      .then(unwrapResult)
      .then((updated_offer: any) => {
        setAlert(true)
        // if (
        //   updated_offer?.vehicle.special_handlings.length > 0 &&
        //   !showSpecialHandlingModal
        // ) {
        //   setShowSpecialHandlingModal(true)
        // }
        navigate(0)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const getAcceptedOffers = () => {
    if (providerId)
      dispatch<any>(
        fetchOffers({
          statuses: [
            'ACCEPTED',
            'IN_PROGRESS',
            'INSPECTION_IN_PROGRESS',
            'DIAGNOSTIC_IN_PROGRESS',
            'PENDING_APPROVAL',
          ],
          providerId,
        })
      )
        .then(unwrapResult)
        .then((data: any) => {
          setAcceptedOffers(data)
        })
        .catch((rejectedValueOrSerializedError: any) => {
          console.log('error')
        })
  }

  useEffect(() => {
    getAcceptedOffers()

    if (providerId) {
      socket.emit('subscribe', { branchId: providerId })
      socket.on('status-update', (payload) => {
        getAcceptedOffers()
      })
    }

    return () => {
      socket.off('status-update')
    }
  }, [])

  const btnText = (row: Offer) => {
    const { status, files } = row
    const lowerCaseStatus = status.toLowerCase()

    if (lowerCaseStatus === 'accepted') {
      return 'Mark as arrived'
    } else if (lowerCaseStatus === 'in_progress') {
      return 'Mark as complete'
    } else if (lowerCaseStatus === 'diagnostic_in_progress') {
      if (files.length > 0) {
        const estimateFile = files.find((el) => el.type === 'ESTIMATE')
        if (estimateFile && estimateFile.is_approved === false) {
          return 'Upload diagnostic'
        }
        return 'Mark as complete'
      }
      return 'Upload diagnostic'
    } else if (lowerCaseStatus === 'pending_approval') {
      return 'Mark as complete'
    } else if (lowerCaseStatus === 'inspection_in_progress') {
      if (files.length > 0) {
        const estimateFile = files.find((el) => el.type === 'WORK_ORDER')
        if (estimateFile && estimateFile.is_approved === false) {
          return 'Edit and re-upload'
        }
        return 'Complete inspection'
      }
      return 'Complete inspection'
    } else {
      return 'No Status'
    }
  }

  const statusText = (row: Offer) => {
    const { status, files } = row
    const lowerCaseStatus = status.toLowerCase()

    if (lowerCaseStatus === 'accepted') {
      return 'Mark as arrived'
    } else if (lowerCaseStatus === 'in_progress') {
      if (files.length > 0) {
        const invoiceFile = files.find((el) => el.type === 'INVOICE')
        if (invoiceFile) {
          if (invoiceFile.is_approved === false) {
            return 'Invoice needs changes; see feedback and resubmit'
          }
          return 'Invoice review in progress'
        }
      }
      return 'Service in progress'
    } else if (lowerCaseStatus === 'diagnostic_in_progress') {
      if (files.length > 0) {
        const estimateFile = files.find((el) => el.type === 'ESTIMATE')
        if (estimateFile && estimateFile.is_approved === false) {
          return 'Diagnostic needs changes; see feedback and resubmit'
        }
        return 'Awaiting technician approval'
      }
      return 'Diagnostic in progress'
    } else if (lowerCaseStatus === 'pending_approval') {
      return 'Awaiting customer approval'
    } else if (lowerCaseStatus === 'inspection_in_progress') {
      if (files.length > 0) {
        const estimateFile = files.find((el) => el.type === 'WORK_ORDER')
        if (estimateFile && estimateFile.is_approved === false) {
          return 'Recommended services declined by technician'
        }
        return 'Recommended services awaiting technician approval'
      }
      return 'Inspection in progress'
    } else {
      return 'No Status'
    }
  }

  const onStatusChange = (offer: Offer) => {
    if (currentVehicle?.vin) {
      if (!offer.in_mileage) {
        setShowMileageInModal(true)
      } else if (!offer.out_mileage) {
        setShowMileageOutModal(true)
      }
    } else {
      setShowVinModal(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = useCallback((event: React.MouseEvent, offer: Offer) => {
    event.stopPropagation()
    setOpen(true)
    setSelectedOffer(offer)
  }, [])

  const handleDiagnosticModalClose = () => {
    setShowDiagnosticUploadModal(false)
  }

  const handleDiagnosticUploadSubmit = () => {
    setShowDiagnosticUploadModal(false)
  }

  const handleInspectionModalClose = () => {
    setShowInspectionUploadModal(false)
  }

  const handleInspectionUploadSubmit = () => {
    setShowInspectionUploadModal(false)
  }

  const handleInvoiceModalClose = () => {
    setShowInvoiceUploadModal(false)
  }

  const handleRecommendMoreClose = () => {
    setShowRecommendMoreModal(false)
  }

  const handleSpecialHandlingClose = () => {
    setShowSpecialHandlingModal(false)
    setShowCallCustomerModal(true)
  }

  const handleCallCustomerClose = () => {
    setShowCallCustomerModal(false)
  }

  const handleMileageOutSubmit = (mileage: string) => {
    if (!selectedOffer.id) {
      return
    }

    try {
      const update_offer: UpdateOffer = {
        id: selectedOffer.id,
        ...(mileage && { out_mileage: parseFloat(mileage.replace(/,/g, '') ?? '0') }),
        status: 'READY_FOR_PICKUP',
      }
      dispatch<any>(updateOfferStatus(update_offer))
        .then(unwrapResult)
        .then((updated_offer: any) => {
          setShowInvoiceUploadModal(false)
          getAcceptedOffers()
        })
        .catch((err: any) => {
          console.log(err)
        })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleInvoiceUploadSubmit = async () => {
    setShowInvoiceUploadModal(false)
    setShowMileageOutModal(true)
  }

  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          marginLeft: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          marginBottom: -8,
        }}
      >
        <AcceptedOffersIcon />
        <Typography
          sx={{
            marginTop: 4,
            fontFamily: APP_FONT,
            fontSize: 14,
            letterSpacing: 1,
            textTransform: 'uppercase',
            color: '#AEAEAE',
          }}
          id="tableTitle"
          component="div"
        >
          Accepted
        </Typography>
      </Box>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer sx={{ px: 2 }}>
          <Table aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
              onRequestSort={function (
                event: React.MouseEvent<unknown, MouseEvent>,
                property: keyof Data
              ): void {
                throw new Error('Function not implemented.')
              }}
              onSelectAllClick={function (event: React.ChangeEvent<HTMLInputElement>): void {
                throw new Error('Function not implemented.')
              }}
            />
            <TableBody>
              {localAcceptedOffers?.map((row: Offer) => (
                <TableRow key={row.id}>
                  {/* <TableCell align="left">
                    {row.is_automated && (
                      <ArrowTooltip title="Automated offer">
                        <IconButton>
                          <img src={Automated} alt="Automated" />
                        </IconButton>
                      </ArrowTooltip>
                    )}
                  </TableCell> */}
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {row.consumer_request.slug ?? row.consumer_request.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">{`${row.vehicle?.year} ${
                    row.vehicle?.make
                  } ${row.vehicle?.model} ${row.vehicle.trim ?? ''}`}</TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }}>{row.vehicle?.name}</TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.vehicle?.vin || 'VIN not provided'}
                      {row.vehicle?.vin ? (
                        <ArrowTooltip title={copiedVin === row.vehicle?.vin ? 'Copied!' : ''}>
                          <StyledButton
                            onClick={(event) => handleCopyVin(event, row.vehicle?.vin ?? '')}
                          >
                            <img src={copiedVin === row.vehicle?.vin ? CopyFilled : Copy} alt="" />
                          </StyledButton>
                        </ArrowTooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.vehicle?.class?.name ? `Class ${row.vehicle?.class?.name}` : ''}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {[row.vehicle?.engines].join(', ')}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    {row.vehicle?.engine_size}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <ServiceList row={row} filter={true} />
                    {row?.services?.length - 3 > 0 && (
                      <HoverPopper
                        holder={
                          <Typography
                            sx={{
                              color: '#DB5D08',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {`(${row?.services?.length || 1}) View all`}
                          </Typography>
                        }
                      >
                        <Box
                          sx={{
                            backgroundColor: 'rgba(238, 238, 238, 0.95)',
                            pointerEvents: 'auto',
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 1,
                          }}
                        >
                          {row.services.map((item, index) => {
                            let displayName = item?.service.name
                            if (
                              item?.service?.parent &&
                              item.service.parent.parent_id &&
                              item.service.parent.name
                            ) {
                              displayName = `${item.service.parent.name} (${displayName}${
                                item.service.type === 'TIRE' &&
                                row.vehicle.tires &&
                                row.vehicle.tires.length > 0
                                  ? `, ${row.vehicle.tires[0].width}/${row.vehicle.tires[0].ratio}${row.vehicle.tires[0].construction}${row.vehicle.tires[0].diameter}`
                                  : ''
                              })`
                            }

                            return (
                              <Typography
                                key={`a-${item.service.name}-${index}`}
                                mb={1.5}
                                fontFamily={APP_FONT}
                                fontSize={'14px'}
                              >
                                {displayName}
                              </Typography>
                            )
                          })}
                        </Box>
                      </HoverPopper>
                    )}
                  </TableCell>

                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {!isNaN(
                      Number(row.new_supply_fee ? row.new_supply_fee : row.supply_fee) +
                        Number(row.new_tax ? row.new_tax : row.tax) +
                        row.services
                          .filter((service) => service.is_approved)
                          .reduce(
                            (total, service) =>
                              total + (service.price ? parseFloat(service.price) : 0),
                            0
                          )
                    )
                      ? formatPrice(
                          Number(row.new_supply_fee ? row.new_supply_fee : row.supply_fee) +
                            Number(row.new_tax ? row.new_tax : row.tax) +
                            row.services
                              .filter((service) => service.is_approved)
                              .reduce(
                                (total, service) =>
                                  total + (service.price ? parseFloat(service.price) : 0),
                                0
                              )
                        )
                      : Number(row.total_fee)}
                  </TableCell>

                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {row.provider_drop_off_by
                      ? formatDateTime(row.provider_drop_off_by)
                      : formatDateTime(row.drop_off_by) || 'Not Selected'}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {formatDateTime(row.pick_up_after) || 'Not Selected'}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {row.is_automated && (
                      <Button
                        sx={{
                          borderRadius: 24,
                          minWidth: 200,
                          backgroundColor: '#FFFFFF',
                          fontSize: 12,
                          fontWeight: 700,
                          textTransform: 'none',
                          fontFamily: 'Montserrat',
                          color: '#FF6600',
                          border: '2px solid #FF6600',
                          '&:hover': {
                            color: '#FFFFFF',
                            borderColor: '#1976d2',
                          },
                        }}
                        variant="contained"
                        onClick={(event) => handleOpen(event, row)}
                        disabled={row.pick_up_after_is_verified}
                      >
                        Override pick-up time
                      </Button>
                    )}
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.consumer_request.additional_information ? (
                        <>
                          {row.consumer_request.additional_information.length > 104 ? (
                            <HoverPopper
                              holder={
                                <span>
                                  {row.consumer_request.additional_information.substring(0, 104)}
                                  <span style={{ color: '#FF7D26' }}>...</span>
                                </span>
                              }
                            >
                              <Box
                                sx={{
                                  width: '500px',
                                  textAlign: 'center',
                                  backgroundColor: 'rgba(238, 238, 238, 0.95)',
                                  pointerEvents: 'auto',
                                  p: 2,
                                }}
                              >
                                {row.consumer_request.additional_information}
                              </Box>
                            </HoverPopper>
                          ) : (
                            row.consumer_request.additional_information
                          )}
                          <ArrowTooltip
                            title={
                              copiedComment === row.consumer_request.additional_information
                                ? 'Copied!'
                                : ''
                            }
                          >
                            <StyledButton
                              onClick={(e: any) =>
                                handleCopyComment(
                                  e,
                                  row.consumer_request.additional_information ?? ''
                                )
                              }
                            >
                              <img
                                src={
                                  copiedComment === row.consumer_request.additional_information
                                    ? CopyFilled
                                    : Copy
                                }
                                alt="Copy icon"
                              />
                            </StyledButton>
                          </ArrowTooltip>
                        </>
                      ) : (
                        'No additional information'
                      )}
                    </div>
                  </TableCell>
                  <TableCell sx={{ fontFamily: APP_FONT }} align="left">
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                      {row.vehicle.special_handlings && row.vehicle.special_handlings.length > 0 ? (
                        <HoverPopper
                          holder={<InfoChip label={row.vehicle.special_handlings[0].title} />}
                        >
                          <Box
                            sx={{
                              width: '500px',
                              textAlign: 'center',
                              backgroundColor: 'rgba(238, 238, 238, 0.95)',
                              pointerEvents: 'auto',
                              paddingTop: 1,
                              paddingLeft: 3,
                              paddingRight: 3,
                              paddingBottom: 1,
                            }}
                          >
                            {' '}
                            {row.vehicle.special_handlings[0].description}
                          </Box>
                        </HoverPopper>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {statusText(row)}
                  </TableCell>
                  <TableCell style={{ fontFamily: APP_FONT }} align="left">
                    {
                      <Button
                        disabled={
                          row.status.toLowerCase() === 'pending_approval' ||
                          (row.status.toLowerCase() === 'diagnostic_in_progress' &&
                            row.files.some(
                              (el) => el.type === 'ESTIMATE' && el.is_approved !== false
                            )) ||
                          (row.status.toLowerCase() === 'in_progress' &&
                            row.files.some(
                              (el) => el.type === 'INVOICE' && el.is_approved !== false
                            )) ||
                          (row.status.toLowerCase() === 'inspection_in_progress' &&
                            row.files.some(
                              (el) => el.type === 'WORK_ORDER' && el.is_approved !== false
                            ))
                        }
                        onClick={(event) => {
                          event.stopPropagation()
                          setSelectedOffer(row)
                          setCurrentVehicle(row.vehicle)
                          if (row.status.toLowerCase() === 'accepted') {
                            setShowMarkAsArrivedModal(true)
                          } else if (row.status.toLowerCase() === 'diagnostic_in_progress') {
                            event.stopPropagation()
                            setShowDiagnosticUploadModal(true)
                          } else if (row.status.toLowerCase() === 'inspection_in_progress') {
                            event.stopPropagation()
                            setShowInspectionUploadModal(true)
                          } else {
                            setSelectedOfferId(row.id)
                            setShowInvoiceUploadModal(true)
                          }
                        }}
                        sx={{
                          backgroundColor:
                            row.status === 'ACCEPTED'
                              ? '#F9A825'
                              : row.status === 'DIAGNOSTIC_IN_PROGRESS'
                              ? row.files.length > 0 &&
                                row.files.some(
                                  (el) => el.type === 'ESTIMATE' && el.is_approved !== false
                                )
                                ? '#0000001F'
                                : '#9C27B0'
                              : row.status === 'PENDING_APPROVAL'
                              ? '#0000001F'
                              : row.files.length > 0 &&
                                row.files.some(
                                  (el) => el.type === 'INVOICE' && el.is_approved !== false
                                )
                              ? '#0000001F'
                              : row.status === 'INSPECTION_IN_PROGRESS'
                              ? row.files.length > 0 &&
                                row.files.some(
                                  (el) => el.type === 'WORK_ORDER' && el.is_approved !== false
                                )
                                ? '#0000001F'
                                : row.files.some(
                                    (el) => el.type === 'WORK_ORDER' && el.is_approved === false
                                  )
                                ? '#9C27B0'
                                : '#FF6600'
                              : '#39C815',
                          borderRadius: 24,
                          width: 160,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontFamily: APP_FONT,
                            fontWeight: '700',
                            color: '#FFFFFF',
                            textTransform: 'none',
                          }}
                        >
                          {btnText(row)}
                        </Typography>
                      </Button>
                    }
                    {showVinModal && (
                      <VinModal
                        isOpen={showVinModal}
                        setIsOpen={setShowVinModal}
                        updateVehicle={updateVehicle}
                        offer={selectedOffer}
                      />
                    )}
                    {showMileageInModal && (
                      <MileageInModal
                        isOpen={showMileageInModal}
                        setIsOpen={setShowMileageInModal}
                        updateMileage={updateOfferMileage}
                        offer={selectedOffer}
                      />
                    )}
                    {showMileageOutModal && (
                      <MileageOutModal
                        isOpen={showMileageOutModal}
                        setIsOpen={setShowMileageOutModal}
                        updateMileage={handleMileageOutSubmit}
                        offer={selectedOffer}
                      />
                    )}
                    {showMarkAsArrivedModal && (
                      <MarkAsArrivedModal
                        isOpen={showMarkAsArrivedModal}
                        setIsOpen={setShowMarkAsArrivedModal}
                        markAsArrived={onStatusChange}
                        offer={selectedOffer}
                      />
                    )}
                    {showMarkAsCompletedModal && (
                      <MarkAsCompleteModal
                        isOpen={showMarkAsCompletedModal}
                        setIsOpen={setShowMarkAsCompletedModal}
                        offer={selectedOffer}
                        markAsComplete={onStatusChange}
                      />
                    )}
                    {selectedOffer && selectedOffer.is_automated && (
                      <SetUpPickUpModal
                        setOpen={setOpen}
                        open={open}
                        onHide={handleClose}
                        offer={selectedOffer}
                      />
                    )}
                    {selectedOffer && showDiagnosticUploadModal && services.length > 0 && (
                      <DiagnosticUploadModal
                        setOpen={setShowDiagnosticUploadModal}
                        open={showDiagnosticUploadModal}
                        onClose={handleDiagnosticModalClose}
                        onSubmit={handleDiagnosticUploadSubmit}
                        offer={selectedOffer}
                        services={services}
                      />
                    )}
                    {selectedOffer && showInspectionUploadModal && services.length > 0 && (
                      <InspectionUploadModal
                        setOpen={setShowInspectionUploadModal}
                        open={showInspectionUploadModal}
                        onClose={handleInspectionModalClose}
                        onSubmit={handleInspectionUploadSubmit}
                        offer={selectedOffer}
                        services={services}
                      />
                    )}
                    {selectedOffer && showInvoiceUploadModal && (
                      <InvoiceUploadModal
                        setOpen={setShowInvoiceUploadModal}
                        open={showInvoiceUploadModal}
                        onClose={handleInvoiceModalClose}
                        onSubmit={handleInvoiceUploadSubmit}
                        offer={selectedOffer}
                      />
                    )}
                    {selectedOffer && showSpecialHandlingModal && (
                      <SpecialHandlingModal
                        isOpen={showSpecialHandlingModal}
                        onClose={handleSpecialHandlingClose}
                        offer={selectedOffer}
                      />
                    )}
                    {selectedOffer && showRecommendMoreModal && (
                      <RecommendServicesModal
                        setOpen={setShowRecommendMoreModal}
                        open={showRecommendMoreModal}
                        onClose={handleRecommendMoreClose}
                        offer={selectedOffer}
                      />
                    )}
                    {showCallCustomerModal && (
                      <CallCustomerModal
                        isOpen={showCallCustomerModal}
                        setIsOpen={setShowCallCustomerModal}
                        onClose={handleCallCustomerClose}
                        offer={selectedOffer}
                        getAcceptedOffers={getAcceptedOffers}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}
