import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { ActionTypes } from '../constants/action-types'
import { CreatePerk, DeletePerk, FetchPerks, UpdatePerk, UpdatePerkOrder } from '../../models/perks'
import { handleErrors } from '../../utils/helper'

export const fetchPerks = createAsyncThunk(
  ActionTypes.FETCH_SERVICES,
  async (params: FetchPerks, { rejectWithValue }) => {
    try {
      const data = await apiCall('provider/catalog/perks', 'get', undefined, params)
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const createPerk = createAsyncThunk(
  ActionTypes.CREATE_PERK,
  async (params: CreatePerk, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/branch/${params.provider_branch_id}/perks`,
        'post',
        { shop_perk_ids: params.shop_perk_ids },
        undefined
      )
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const updatePerkMetaData = createAsyncThunk(
  ActionTypes.UPDATE_PERK,
  async (params: UpdatePerk, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/branch/${params.provider_branch_id}/perks/${params.shop_perk_id}`,
        'patch',
        { meta_data: params.meta_data },
        undefined
      )
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const updatePerkOrder = createAsyncThunk(
  ActionTypes.UPDATE_PERK,
  async (params: UpdatePerkOrder, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/branch/${params.provider_branch_id}/perks`,
        'patch',
        { items: params.items },
        undefined
      )
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const deletePerk = createAsyncThunk(
  ActionTypes.DELETE_PERK,
  async (params: DeletePerk, { rejectWithValue }) => {
    try {
      const data = await apiCall(
        `provider/branch/${params.provider_branch_id}/perks`,
        'delete',
        undefined,
        { shop_perk_ids: params.shop_perk_ids }
      )
      return data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)
