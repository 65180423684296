import React from 'react'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'
import styled from 'styled-components'
import { RfPadSelect } from './RfPadSelect'
import { LfPadSelect } from './LfPadSelect'
import { RrPadSelect } from './RrPadSelect'
import { LrPadSelect } from './LrPadSelect'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
`

export type AxlesFields = 'RF_PAD' | 'RR_PAD' | 'LF_PAD' | 'LR_PAD'

type AxlesProps = {
  values: {
    [key in AxlesFields]: string
  }
  onChange: (field: AxlesFields, value: string) => void
  isFront: boolean
  isRear: boolean
}

export const Axles = ({ values, onChange, isFront, isRear }: AxlesProps) => {
  const areBothAxles = isFront && isRear

  let label = ''

  if (areBothAxles) {
    label = 'Please select the measurements for both axles:'
  } else if (isFront) {
    label = 'Please select the measurements for the front axle:'
  } else {
    label = 'Please select the measurements for the rear axle:'
  }

  if (!isFront && !isRear) {
    return null
  }

  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          marginRight: '8px',
          fontSize: '14px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        {label}
      </Typography>
      <Row>
        {isFront ? (
          <>
            <RfPadSelect value={values.RF_PAD} onChange={(value) => onChange('RF_PAD', value)} />
            <LfPadSelect value={values.LF_PAD} onChange={(value) => onChange('LF_PAD', value)} />
          </>
        ) : null}
        {isRear ? (
          <>
            <RrPadSelect value={values.RR_PAD} onChange={(value) => onChange('RR_PAD', value)} />
            <LrPadSelect value={values.LR_PAD} onChange={(value) => onChange('LR_PAD', value)} />
          </>
        ) : null}
      </Row>
    </div>
  )
}
