import { Button, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { APP_FONT } from '../../constants/app_font'
import ModalTableHeadShort from '../../pages/dashboard/tables/modals/components/table_head_short'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxHeight: '90vh',
  overflowY: 'auto',
}

export default function MarkAsArrivedModal(props: any) {
  const { isOpen, setIsOpen, markAsArrived, offer } = props

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => setIsOpen(!isOpen)}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            mb: 5,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#FF6600',
              mr: 4,
            }}
          >
            Mark vehicle as arrived?
          </Typography>

          <Box
            component="span"
            display="flex"
            justifyContent="right"
            marginTop={'22px'}
            sx={{ ml: 25 }}
          >
            <Button
              onClick={() => setIsOpen(!isOpen)}
              sx={{
                width: 75,
                height: 30,
                borderRadius: '20px',
                mr: 2,
              }}
              color="inherit"
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  color: '#FF6600',
                  fontFamily: APP_FONT,
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={() => {
                markAsArrived(offer)
                setIsOpen(!isOpen)
              }}
              sx={{ width: 95, height: 30, borderRadius: '20px' }}
              variant="contained"
              color="warning"
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontSize: '13px',
                  fontFamily: APP_FONT,
                  fontWeight: 'bold',
                }}
              >
                Confirm
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
