import { Decimal } from 'decimal.js'

export function formatPrice(price: string | number | Decimal) {
  const numericPrice =
    price instanceof Decimal ? price.toNumber() : parseFloat(sanitizeNumber(price).toString())

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(numericPrice)
}
export function sanitizeNumber(value) {
  if (typeof value === 'string') {
    return parseFloat(value.replace(/,/g, '')) || 0
  }
  return value || 0
}
