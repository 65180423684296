import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const CountdownTimer = (props: any) => {
  const { initialTime, isOffer } = props
  const [timeLeft, setTimeLeft] = useState(initialTime)
  const dispatch = useDispatch()

  const getTimeLeftDisplay = (minutes: number) => {
    if (minutes <= 0) return '0'

    const days = Math.floor(minutes / (24 * 60))
    const totalHours = Math.floor(minutes / 60)
    const hours = totalHours % 24
    const mins = minutes % 60

    if (totalHours < 1) {
      return `${mins} minute${mins !== 1 ? 's' : ''}`
    } else if (totalHours < 24) {
      return `${totalHours} hour${totalHours !== 1 ? 's' : ''} ${mins} minute${
        mins !== 1 ? 's' : ''
      }`
    } else if (totalHours === 24) {
      return `24 hours`
    } else if (totalHours < 48) {
      return `1 day ${hours} hour${hours !== 1 ? 's' : ''} ${mins} minute${mins !== 1 ? 's' : ''}`
    } else {
      return `${days} days ${hours} hour${hours !== 1 ? 's' : ''}`
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft: number) => prevTimeLeft - 1)
      } else if (timeLeft === 0) {
        clearInterval(interval)
      }
      if (timeLeft === 1 && props.onTimerExpire) {
        props.onTimerExpire(props.request_id)
      }
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [timeLeft, dispatch])

  let content = isOffer
    ? timeLeft < 0
      ? `Offer expired`
      : `User has <strong>${getTimeLeftDisplay(timeLeft)}</strong>`
    : `Respond in <strong>${getTimeLeftDisplay(timeLeft)}</strong>`

  return (
    <p
      style={{
        whiteSpace: 'nowrap',
        fontWeight: '400',
        fontSize: 14,
        color: '#FF6600',
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
