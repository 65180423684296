import { useCallback } from 'react'
import { useListServicesTreeQuery } from '../redux/api/serviceApi/serviceApi'
import { Service } from '../redux/api/serviceApi/serviceApi.types'

type ParentService =
  | (Service & {
      child?: Service | null
    })
  | null

export const useGetParentService = (serviceId?: string) => {
  //   this hook is used to get the parent service of a service and type which is a service of received serviceId if it is a sub-service.
  //   If the service is a sub-service, it will return the parent service.
  //   If the service is a parent service, it will return the service itself.
  //   If the service is not found, it will return null.
  const { data, isFetching, isSuccess } = useListServicesTreeQuery()

  const getParentService = useCallback(
    (serviceId: string, isOffer: boolean = false): ParentService => {
      const selectedService = data?.mapping[serviceId]
      if (selectedService?.parent_id) {
        const parentService = data?.mapping[selectedService.parent_id]
        if (parentService) {
          const isAlreadyParent = isOffer ? parentService.level === 1 : parentService.level === 0

          if (isAlreadyParent) {
            return { ...selectedService, child: null }
          } else {
            return { ...parentService, child: selectedService }
          }
        } else {
          return null
        }
      } else {
        return selectedService || null
      }
    },
    [data]
  )

  return {
    data: serviceId ? getParentService(serviceId) : null,
    getParentService,
    isLoading: isFetching,
    isSuccess,
  }
}
