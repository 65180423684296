import React from 'react'
import { Typography } from '@mui/material'
import { APP_FONT } from '../../../../../../constants/app_font'
import styled from 'styled-components'
import { ColdCrankingAmpsInput } from './ColdCrankingAmpsInput'
import { FactoryColdCrankingAmpsInput } from './FactoryColdCrankingAmpsInput'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
`

export type CrankingAmpsFields = 'COLD_CRANKING_AMPS' | 'FACTORY_COLD_CRANKING_AMPS'

type CrankingAmpsProps = {
  values: {
    [key in CrankingAmpsFields]: string
  }
  onChange: (field: CrankingAmpsFields, value: string) => void
}

export const CrankingAmps = ({ values, onChange }: CrankingAmpsProps) => {
  return (
    <div>
      <Typography
        sx={{
          fontFamily: APP_FONT,
          marginRight: '8px',
          fontSize: '14px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        Please provide the Cold Cranking Amps and Factory Cold Cranking Amps for the vehicle:
      </Typography>
      <Row>
        <ColdCrankingAmpsInput
          value={values.COLD_CRANKING_AMPS}
          onChange={(value) => onChange('COLD_CRANKING_AMPS', value)}
        />
        <FactoryColdCrankingAmpsInput
          value={values.FACTORY_COLD_CRANKING_AMPS}
          onChange={(value) => onChange('FACTORY_COLD_CRANKING_AMPS', value)}
        />
      </Row>
    </div>
  )
}
