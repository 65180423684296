import { useEffect, useState } from 'react'
import axios, { CancelTokenSource } from 'axios'

export interface PlaceAutocompleteResponse {
  items: any
  predictions: string[]
  status: string
}

export interface MatchedSubstringsEntityOrMainTextMatchedSubstringsEntity {
  length: number
  offset: number
}
export interface StructuredFormatting {
  main_text: string
  main_text_matched_substrings?: MatchedSubstringsEntityOrMainTextMatchedSubstringsEntity[] | null
  secondary_text: string
}
export interface TermsEntity {
  offset: number
  value: string
}

export const usePlacesAutocomplete = () => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let cancelTokenSource: CancelTokenSource

    const fetchSuggestions = async () => {
      if (value.trim() === '') {
        setSuggestions([])
        return
      }

      setLoading(true)

      try {
        cancelTokenSource = axios.CancelToken.source()

        const response = await axios.get(
          process.env.REACT_APP_NEST_JS_API + 'provider/places/autocomplete',
          {
            params: {
              value,
              radius: 50000,
              components: 'country:us',
            },
            cancelToken: cancelTokenSource.token,
          }
        )

        const predictions = response.data.items
        setSuggestions(predictions)
        setLoading(false)
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled
          console.log('Request canceled')
        } else {
          // Handle other errors
          console.error(error)
        }

        setLoading(false)
      }
    }

    fetchSuggestions()

    // Cleanup function to cancel the request if the component unmounts or value changes
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
      }
    }
  }, [value])

  const handleChange = (newValue: string) => {
    setValue(newValue)
  }

  return { value, suggestions, loading, handleChange }
}
