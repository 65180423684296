import { createSlice } from '@reduxjs/toolkit';
import { fetchLoggedInUser, loginUser, logoutUser, registerUser, userIsAuth } from '../actions/user';

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;

const initialState = {
  loading: false,
  userInfo: null,
  userToken: userToken, // for storing the JWT
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Register user
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.userInfo = payload;
        state.userToken = payload.userToken;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      })

      // Login user
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.userInfo = payload;
        state.userToken = payload.userToken;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      })

      // Is auth user
      .addCase(userIsAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userIsAuth.fulfilled, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.userInfo = payload;
      })
      .addCase(userIsAuth.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      })

      // Logout user
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
      })
      .addCase(logoutUser.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      })

      // Fetch user
      .addCase(fetchLoggedInUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoggedInUser.fulfilled, (state) => {
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
      })
      .addCase(fetchLoggedInUser.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      });
  },
});

export default userSlice.reducer;
