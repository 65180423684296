import { createSlice } from '@reduxjs/toolkit';
import { fetchServiceRequests } from '../actions/service_request';

const initialState = {
  loading: false,
  service_request: null,
  error: null,
  success: false,
};

const serviceRequestSlice = createSlice({
  name: 'service_request',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Service Requests
      .addCase(fetchServiceRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceRequests.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.service_request = payload;
      })
      .addCase(fetchServiceRequests.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      });
  },
});

export default serviceRequestSlice.reducer;
