import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { listValidations } from '../../../../../../redux/api/serviceApi/listValidations'

type LrPadSelectProps = {
  value: string
  onChange: (value: string) => void
}

export const LrPadSelect = ({ value, onChange }: LrPadSelectProps) => {
  const items = listValidations.LR_PAD.permitted_values

  return (
    <Autocomplete
      size="small"
      value={value || ''}
      onChange={(event, newValue: any) => onChange(newValue)}
      options={items}
      getOptionLabel={(option) => option}
      getOptionKey={(option) => option}
      renderInput={(params) => (
        <TextField {...params} label="LR pad" placeholder="Select or search" />
      )}
      isOptionEqualToValue={(option, value) => option === value}
      sx={{ width: '100%' }}
      disableClearable
      freeSolo
    />
  )
}
