import { createSlice } from '@reduxjs/toolkit';
import { fetchAccount } from '../actions/account_settings';

const initialState = {
  loading: false,
  account_settings: null,
  error: null,
  success: false,
};

const accountSettingsSlice = createSlice({
  name: 'account_settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        // @ts-ignore
        state.account_settings = payload;
      })
      .addCase(fetchAccount.rejected, (state, { payload }) => {
        state.loading = false;
        // @ts-ignore
        state.error = payload;
      });
  },
});

export default accountSettingsSlice.reducer;
