import { useState } from 'react'

import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ReactComponent as CarmaLogo } from '../../../assets/images/carma_logo_white.svg'
import loginImage from '../../../assets/images/loginImage.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from '../../../redux/actions/user'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { APP_FONT } from '../../../constants/app_font'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

type RegisterBase = {
  password: string
  confirmpassword: string
  verificationCode: string
}

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
})

const ResetPassword = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get('email')
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState()
  const navigate = useNavigate()

  const submit: SubmitHandler<any> = async (register: RegisterBase) => {
    setLoading(true)
    const user: any = {
      password: register.password,
      email: email,
      code: register.verificationCode,
    }
    dispatch<any>(resetPassword(user))
      .then(unwrapResult)
      .then((res: any) => {
        setLoading(false)
        if (res == 'SUCCESS') {
          navigate('/')
          window.alert('Password has been reset, please login')
        } else {
          setLoading(false)
          setError(res.message)
        }
      })
      .catch((err: any) => {
        setLoading(false)
        setError(err)
      })
  }
  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Invalid password format'
      ),
    confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
    verificationCode: Yup.string()
      .required('Verification code is required')
      .max(8, 'Verification can be at most 8 digits'),
  })
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const validateField = (fieldName: 'verificationCode' | 'password' | 'confirmpassword') => {
    trigger(fieldName)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#FF6600',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <CarmaLogo width={275} height={79} style={{ marginTop: 30 }} />
          <Box
            sx={{
              mx: 8,
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '4%',
            }}
          >
            <Typography
              component="h6"
              variant="h6"
              fontWeight="bold"
              fontSize="14px"
              fontFamily="Montserrat"
            >
              Welcome, please reset your password.
            </Typography>
            <Box component="form">
              <Controller
                name="verificationCode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      validateField('verificationCode')
                    }}
                    type="number"
                    label="Verification code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.verificationCode}
                    helperText={errors.verificationCode?.message?.toString()}
                    InputLabelProps={{
                      style: { fontFamily: 'Montserrat' },
                    }}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      validateField('password')
                    }}
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password?.message?.toString()}
                    InputLabelProps={{
                      style: { fontFamily: 'Montserrat' },
                    }}
                  />
                )}
              />
              <p style={{ color: 'gray', fontSize: 10, marginTop: 0 }}>
                (Password must contain at least one letter, one number, and one special character)
              </p>

              <Controller
                name="confirmpassword"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      validateField('confirmpassword')
                    }}
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.confirmpassword}
                    helperText={errors.confirmpassword?.message?.toString()}
                    InputLabelProps={{
                      style: { fontFamily: 'Montserrat' },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? (
                              <VisibilityOff color="action" />
                            ) : (
                              <Visibility color="action" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {error && (
                <Typography fontSize="14px" color="error" textAlign={'center'}>
                  {error}
                </Typography>
              )}

              <Button
                onClick={handleSubmit(submit)}
                type="submit"
                style={{ fontFamily: 'Montserrat' }}
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 1,
                  borderRadius: 10,
                  backgroundColor: 'black',
                }}
              >
                Reset Password
              </Button>
            </Box>
          </Box>
          {isLoading && <CircularProgress color="inherit" />}
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ResetPassword
