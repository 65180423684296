import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InfoChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: '1px solid #FF7D26',
    borderRadius: '16px',
    color: '#FF7D26',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    height: 'auto',
    padding: theme.spacing(0.5, 2),
    '& .MuiChip-label': {
      padding: 0,
    },
  }))