import React, { useMemo } from 'react'
import { Box, Button, Grid, IconButton, InputLabel, TextField, Typography } from '@mui/material'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import { Decimal } from 'decimal.js'
import { APP_FONT } from '../../../../../constants/app_font'
import { TirePositions } from '../inspection_upload/selects/TirePositions'
import { CrankingAmps } from '../inspection_upload/selects/CrankingAmps'
import { Axles } from '../inspection_upload/selects/Axles'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { TireInfo } from '../inspection_upload/selects/TireInfo'
import { Vehicle } from '../../../../../models/service_request'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { ReactComponent as Plus } from '../../../../../assets/images/plus.svg'
import { RecommendedServicePart } from '../service.model'

const AdditionalDataInfo = ({ label, value }) => {
  return (
    <Box display="flex" mt="5px">
      <Typography
        variant="body1"
        style={{
          fontWeight: 'regular',
          fontFamily: APP_FONT,
          fontSize: 14,
          marginRight: '5px',
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: 'bold', fontFamily: APP_FONT, fontSize: 14 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

type TirePositionsFieldsType = {
  LF_MEASUREMENT?: string
  LR_MEASUREMENT?: string
  RF_MEASUREMENT?: string
  RR_MEASUREMENT?: string
  LR_INSIDE_MEASUREMENT?: string
  RR_INSIDE_MEASUREMENT?: string
  TIRE_POSITIONS: string[]
}

type TireInfoFieldsType = {
  TIRE_BRAND?: string
  TIRE_MODEL?: string
  WARRANTY?: number
}

type CrankingAmpsFieldsType = {
  COLD_CRANKING_AMPS?: string
  FACTORY_COLD_CRANKING_AMPS?: string
}

type AxlesFieldsType = {
  LF_PAD?: string
  LR_PAD?: string
  RF_PAD?: string
  RR_PAD?: string
}

export type ServiceAdditionalData =
  | (TirePositionsFieldsType & TireInfoFieldsType & CrankingAmpsFieldsType & AxlesFieldsType)
  | null

export type OfferBlockService = {
  id: string
  name: string
  type: string | null
  types: any[] | null
  price: number
  serviceType: string
  labor_hours: number
  labor_price: number
  labor_rate: number
  parts: RecommendedServicePart[]
  additional_data: ServiceAdditionalData
  vehicle: Vehicle
}

interface OfferBlockProps {
  service: OfferBlockService
  index: number
  handleServiceChange: (
    index: number,
    field: keyof OfferBlockService | string,
    value: string | string[] | number
  ) => void
  handleServicePartChange: (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => void
  handleAddServicePart: (serviceIndex: number) => void
  handleRemoveServicePart: (serviceIndex: number, partIndex: number) => void
  handleAdditionalDataChange: (
    index: number,
    field: string,
    value: string | string[] | number
  ) => void
  disableServiceChange?: boolean
}

export const OfferServiceBlock: React.FC<OfferBlockProps> = ({
  service,
  index,
  handleServiceChange,
  handleServicePartChange,
  handleAddServicePart,
  handleRemoveServicePart,
  handleAdditionalDataChange,
  disableServiceChange,
}) => {
  const { data: parentService } = useGetParentService(service?.type || service?.id)
  const serviceChild = useMemo(() => parentService?.child, [parentService])

  const recommendedRequiredData = useMemo(() => {
    return serviceChild?.recommend_required_data || parentService?.recommend_required_data || []
  }, [serviceChild, parentService])

  const { IS_TIRE_POSITIONS, IS_TIRE_INFO, IS_CRANKING_AMPS, IS_FRONT_AXLE, IS_REAR_AXLE } =
    useMemo(() => {
      return {
        IS_TIRE_POSITIONS: recommendedRequiredData.includes('TIRE_POSITIONS'),
        IS_TIRE_INFO: recommendedRequiredData.includes('TIRE_BRAND'),
        IS_CRANKING_AMPS: recommendedRequiredData.includes('COLD_CRANKING_AMPS'),
        IS_FRONT_AXLE:
          recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD'),
        IS_REAR_AXLE:
          recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD'),
      }
    }, [recommendedRequiredData])

  const tireInfo = useMemo(() => {
    return service.vehicle?.tires
      ?.map((tire) => `${tire.width}/${tire.ratio}${tire.construction}${tire.diameter}`)
      .join(', ')
  }, [service.vehicle])

  const totalPrice = useMemo(() => {
    return (
      service.parts.reduce((acc, part) => acc + part.price_per_unit * part.quantity, 0) +
      sanitizeNumber(service.labor_hours) * service.labor_rate
    )
  }, [service])

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="center"
        mb={2}
        style={{
          backgroundColor: '#F9FAFB',
          padding: '12px',
          borderRadius: '8px',
          marginTop: '12px',
          marginBottom: '12px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          border: '1px solid #E5E7EB',
        }}
      >
        <Grid item xs={6}>
          <TextField
            disabled={true}
            fullWidth
            label="Service"
            value={
              service.name === 'Full general diagnostic'
                ? 'Full general diagnostic'
                : parentService?.name
            }
            InputProps={{
              readOnly: true,
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#393A3D',
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Type"
            value={serviceChild?.name}
            InputProps={{
              readOnly: true,
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#393A3D',
                },
              },
            }}
            disabled={true}
          />
        </Grid>

        <Box
          sx={{
            padding: '0 16px',
            width: '100%',
          }}
        >
          {IS_TIRE_POSITIONS ? (
            <>
              <AdditionalDataInfo
                label="Tires need to be replaced"
                value={
                  Array.isArray(service.additional_data?.TIRE_POSITIONS)
                    ? service.additional_data?.TIRE_POSITIONS.join(', ')
                    : service.additional_data?.TIRE_POSITIONS || ''
                }
              />
              <AdditionalDataInfo label="Tire size" value={tireInfo} />
              <AdditionalDataInfo
                label="Preferred tire brand"
                value={
                  typeof service.additional_data?.TIRE_BRAND === 'object'
                    ? // @ts-ignore
                      service.additional_data?.TIRE_BRAND?.name || ''
                    : service.additional_data?.TIRE_BRAND || ''
                }
              />

              <TirePositions
                tirePositions={service.additional_data?.TIRE_POSITIONS || []}
                values={{
                  LF_MEASUREMENT: service.additional_data?.LF_MEASUREMENT || '',
                  LR_MEASUREMENT: service.additional_data?.LR_MEASUREMENT || '',
                  RF_MEASUREMENT: service.additional_data?.RF_MEASUREMENT || '',
                  RR_MEASUREMENT: service.additional_data?.RR_MEASUREMENT || '',
                  LR_INSIDE_MEASUREMENT: service.additional_data?.LR_INSIDE_MEASUREMENT || '',
                  RR_INSIDE_MEASUREMENT: service.additional_data?.RR_INSIDE_MEASUREMENT || '',
                }}
                onChange={(field, value) => {
                  handleAdditionalDataChange(index, field, value)
                }}
                onTirePositionsChange={(value) => {
                  handleAdditionalDataChange(index, 'TIRE_POSITIONS', value)
                }}
              />
            </>
          ) : null}
          {IS_TIRE_INFO ? (
            <TireInfo
              values={{
                TIRE_BRAND:
                  typeof service.additional_data?.TIRE_BRAND === 'object'
                    ? // @ts-ignore
                      service.additional_data?.TIRE_BRAND?.name || ''
                    : service.additional_data?.TIRE_BRAND || '',
                // @ts-ignore
                TIRE_MODEL: service.additional_data?.TIRE_MODEL,
                WARRANTY: service.additional_data?.WARRANTY || 0,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          {IS_CRANKING_AMPS ? (
            <CrankingAmps
              values={{
                // @ts-ignore
                COLD_CRANKING_AMPS: service.additional_data?.COLD_CRANKING_AMPS,
                // @ts-ignore
                FACTORY_COLD_CRANKING_AMPS: service.additional_data?.FACTORY_COLD_CRANKING_AMPS,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          <Axles
            values={{
              // @ts-ignore
              LF_PAD: service.additional_data?.LF_PAD,
              // @ts-ignore
              LR_PAD: service.additional_data?.LR_PAD,
              // @ts-ignore
              RF_PAD: service.additional_data?.RF_PAD,
              // @ts-ignore
              RR_PAD: service.additional_data?.RR_PAD,
            }}
            onChange={(field, value) => {
              handleAdditionalDataChange(index, field, value)
            }}
            isFront={IS_FRONT_AXLE}
            isRear={IS_REAR_AXLE}
          />
          <InputLabel
            style={{
              fontFamily: APP_FONT,
              marginTop: '20px',
              marginBottom: '20px',
              fontWeight: 'bold',
              color: '#393A3D',
              fontSize: '14px',
            }}
          >
            Labor:
          </InputLabel>

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <TextField
              disabled={disableServiceChange}
              label="Number of labor hours"
              type="text"
              value={service.labor_hours.toString()}
              onChange={(e) => {
                const input = e.target.value.replace(/[^0-9]/g, '')
                const number = input === '' ? 0 : parseInt(input, 10) / 10
                const formattedNumber = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })
                  .format(number)
                  .slice(1)

                handleServiceChange(index, 'labor_hours', formattedNumber)
                handleServiceChange(
                  index,
                  'labor_price',
                  parseFloat(formattedNumber) * service.labor_rate
                )
              }}
              InputProps={{
                style: {
                  background: '#FFFFFF',
                  borderRadius: '4px',
                  height: '40px',
                },
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
              }}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputLabel
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: 500,
                  marginRight: '8px',
                  color: '#475467',
                  fontSize: '14px',
                }}
              >
                Labor rate: {formatPrice(service.labor_rate)}/hr
              </InputLabel>
              <TextField
                label="Price"
                type="text"
                value={formatPrice(
                  new Decimal(sanitizeNumber(service.labor_hours))
                    .mul(service.labor_rate)
                    .toDecimalPlaces(2)
                )}
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#393A3D',
                    },
                  },
                }}
                disabled
              />
            </Box>
          </Box>

          {service.parts.length > 0 && (
            <Box sx={{ marginTop: '12px' }}>
              <InputLabel
                style={{
                  fontFamily: APP_FONT,
                  marginTop: '20px',
                  marginBottom: '20px',
                  fontWeight: 'bold',
                  color: '#393A3D',
                  fontSize: '14px',
                }}
              >
                Parts:
              </InputLabel>
              {service.parts.map((part, partIndex) => (
                <Box key={partIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      gap: '16px',
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      label="Part name"
                      placeholder="Enter part name"
                      value={part.name}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'name', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      size="small"
                      label="Quantity"
                      type="number"
                      value={part.quantity}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'quantity', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      size="small"
                      label="Price per unit"
                      value={formatPrice(part.price_per_unit)}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'price_per_unit', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      disabled={true}
                      size="small"
                      label="Total"
                      value={formatPrice(part.price_per_unit * part.quantity)}
                      aria-readonly
                      InputProps={{
                        sx: {
                          background: '#fff',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#393A3D',
                          },
                        },
                      }}
                    />
                    <IconButton
                      onClick={() => handleRemoveServicePart(index, partIndex)}
                      size="large"
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Box>
                  <TextField
                    size="small"
                    label="Part number"
                    placeholder="Enter part number"
                    value={part.number}
                    onChange={(e) =>
                      handleServicePartChange(index, partIndex, 'number', e.target.value)
                    }
                    sx={{
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                    InputProps={{
                      style: { background: '#fff' },
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
          {!disableServiceChange && (
            <Button
              startIcon={<Plus />}
              variant="text"
              color="primary"
              onClick={() => handleAddServicePart(index)}
              sx={{
                fontSize: '14px',
                textTransform: 'none',
                color: '#DB5D08',
                fontWeight: 'bold',
                padding: 0,
                paddingLeft: 1,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <span style={{ marginLeft: '10px', margin: 2, padding: 2, alignItems: 'center' }}>
                Add part
              </span>
            </Button>
          )}
          {/* Service Total */}
          <Box
            display="flex"
            mt="12px"
            style={{
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                marginRight: '16px',
                color: '#111827',
              }}
            >
              Service total:
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                fontFamily: APP_FONT,
                fontSize: 16,
                color: '#111827',
              }}
            >
              {formatPrice(totalPrice)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  )
}
