import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  submitTechnicalApproval,
  updateOfferStatus,
  uploadPdf,
} from '../../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from '../components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_FONT } from '../../../../../constants/app_font'
import { Offer } from '../../../../../models/offer_new'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../../assets/images/pdf_download.svg'
import { ReactComponent as Close } from '../../../../../assets/images/close.svg'
import InspectionWithoutServicesModal from '../incpection_without_services'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import ErrorHandler from '../../../error-handler'
import { useUploadRecServiceImagesMutation } from '../../../../../redux/api/serviceApi/serviceApi'
import { RecServiceBlock } from './RecServiceBlock'
import { RecommendedService, RecommendedServicePart } from './../service.model'
import { ServiceValidationKey } from '../../../../../redux/api/serviceApi/listValidations'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import { ReactComponent as AddIcon } from '../../../../../assets/images/add_icon.svg'
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus_white.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import { validateRecommendedServiceFields } from '../service.validation'
import { calculateSubTotalPrice } from '../calculation'

const MAX_FILE_SIZE = 25 * 1024 * 1024

const AdditionalDataInfo = ({ label, value }) => {
  return (
    <Box display="flex" mt="5px">
      <Typography
        variant="body1"
        style={{
          fontWeight: 'regular',
          fontFamily: APP_FONT,
          fontSize: 14,
          marginRight: '5px',
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: 'bold', fontFamily: APP_FONT, fontSize: 14 }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export interface ServiceChild {
  id: string
  name: string
  recommend_required_data: ServiceValidationKey[] | null
  request_required_data: ServiceValidationKey[] | null
}

export interface Service {
  id: string
  name: string
  children: ServiceChild[]
  recommend_required_data: ServiceValidationKey[] | null
  request_required_data: ServiceValidationKey[] | null
  is_photo_upload_required: boolean
}

interface InspectionUploadModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
}

interface ServicePrices {
  [serviceId: string]: string
}

const InspectionUploadModal: React.FC<InspectionUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
  services,
}) => {
  const dispatch = useDispatch()

  const [error, setError] = useState<string | null>(null)
  const [fileWorkOrder, setFileWorkOrder] = useState<File | null>(null)
  const [fileInspection, setFileInspection] = useState<File | null>(null)
  const [filePmi, setFilePmi] = useState<File | null>(null)
  const [uploadStatusPmi, setUploadStatusPmi] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const [uploadStatusInspection, setUploadStatusInspection] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const [uploadStatusWorkOrder, setUploadStatusWorkOrder] = useState<
    'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const fileInputInspectionRef = useRef<HTMLInputElement>(null)
  const fileInputPmiRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [inspectionFeedbackFile, setInspectionFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isInspectionWithoutServicesModalOpen, setInspectionWithoutServicesModalOpen] =
    useState(false)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [subtotalRequestedServices, setSubtotalRequestedServices] = useState<string>('')

  const clearError = () => setError(null)

  const handleUploadClick = (type: string) => {
    type === 'WORK_ORDER'
      ? fileInputInspectionRef.current?.click()
      : fileInputPmiRef.current?.click()
  }

  const handleServicePriceChange = (
    serviceId: string,
    serviceType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [`${serviceId}_${serviceType}`]: formattedNumber,
    }))
  }

  const { isSuccess, getParentService } = useGetParentService()

  useEffect(() => {
    if (isSuccess) {
      const initialRecommendedServices = offer?.services
        .filter((service) => !service.is_approved)
        .map((service) => {
          const serviceId = service.service.id
          const parentService = getParentService(serviceId)
          const matchingService = services.find(
            (s) => s.id === service.service.parent.id || s.id === service.service.id
          )
          if (matchingService) {
            const formattedNumber = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(service.price)
              .slice(1)

            setServicePrices((prevPrices) => ({
              ...prevPrices,
              [`${serviceId}_${service.service.id}`]: formattedNumber,
            }))

            return {
              id: parentService?.id,
              name: parentService?.name,
              type: parentService?.child?.id,
              types: parentService?.children,
              price: service.price,
              serviceType: service.service.type,
              parts: service.parts.map((part) => ({
                name: part.name,
                quantity: part.quantity,
                price_per_unit: part.price_per_unit,
                number: part.number,
              })),
              labor_hours: service.labor_hours,
              ...service.additional_data,
            }
          }
          return null
        })
        .filter((service) => service !== null) as RecommendedService[]

      setRecServices(initialRecommendedServices)
    }
  }, [offer.services, services, isSuccess, getParentService])

  useEffect(() => {
    const newTotal = calculateSubTotalPrice(recServices, offer.labor_rate)
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices, offer.services])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      let requestedServicesTotal = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => {
          return total + sanitizeNumber(service.price)
        }, 0)

      const recommendedServicesTotal = calculateSubTotalPrice(recServices, offer.labor_rate)
      requestedServicesTotal = Math.ceil(requestedServicesTotal * 100) / 100

      return (recommendedServicesTotal + fees + taxes + requestedServicesTotal).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [
    recServices,
    servicePrices,
    shopSupplyFees,
    fee,
    tax,
    subtotalRequestedServices,
    offer.services,
  ])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'WORK_ORDER_FEEDBACK')
      setInspectionFeedbackFile(feedbackFile || null)
    }
  }, [offer])

  const tireInfo = useMemo(() => {
    return offer?.vehicle?.tires
      ?.map((tire) => `${tire.width}/${tire.ratio}${tire.construction}${tire.diameter}`)
      .join(', ')
  }, [offer?.vehicle])

  useEffect(() => {
    const calculateSubtotalRequestedServices = () => {
      const laborCost = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)

      const subtotal =
        laborCost + (parseFloat(offer.tax) || 0) + (parseFloat(offer.supply_fee) || 0)
      setSubtotalRequestedServices(subtotal.toFixed(2))
    }

    calculateSubtotalRequestedServices()
  }, [offer.services, offer.labor_rate, offer.tax, offer.supply_fee])

  const handleFileSelect = (selectedFile: File, fileType: string) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    if (fileType === 'WORK_ORDER') {
      setFileWorkOrder(selectedFile)
    } else if (fileType === 'PMI') {
      setFilePmi(selectedFile)
    } else if (fileType === 'INSPECTION') {
      setFileInspection(selectedFile)
    }
    fileType === 'WORK_ORDER' ? setFileWorkOrder(selectedFile) : setFilePmi(selectedFile)
    setUploadStatusWorkOrder('IDLE')
  }

  const handleUpload = async () => {
    await handleSubmit()
    if (isPmi) {
      await handleUploadPmi()
    } else {
      await handleUploadInspection()
    }
  }

  const handleUploadInspection = async () => {
    if (!fileInspection || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusInspection('ERROR')
      return
    }

    setUploadStatusInspection('UPLOADING')
    const formData = new FormData()
    formData.append('file', fileInspection)

    dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'INSPECTION', formData }))
      .then(unwrapResult)
      .then((data: any) => {
        setUploadStatusInspection('SUCCESS')
      })
      .catch((error: any) => {
        console.error('Error uploading file:', error)
        setUploadStatusInspection('ERROR')
        setError(`Upload failed: ${error.message}`)
        setAlertText(error.message)
      })
  }

  const onConfirmWithoutService = async () => {
    if (isPmi) {
      handleUploadPmi()
    } else {
      confirmWithoutService()
    }
  }

  const handleUploadPmi = async () => {
    if (!filePmi || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatusPmi('ERROR')
      return
    }
    setUploadStatusPmi('UPLOADING')
    const formData = new FormData()
    formData.append('file', filePmi)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'PMI', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatusPmi('SUCCESS')
          if (showAdditionalServices) {
            handleSubmit()
          } else {
            confirmWithoutService()
          }
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatusPmi('ERROR')
      setError(error.message ?? 'ERROR on file upload')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const uploadFeedback = () => {
    switch (uploadStatusWorkOrder || uploadStatusInspection || uploadStatusPmi) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const confirmWithoutService = () => {
    const update_offer = {
      id: offer.id,
      status: 'IN_PROGRESS',
    }
    if (offer.in_mileage) {
      dispatch<any>(updateOfferStatus(update_offer))
        .then(unwrapResult)
        .then((updated_offer: any) => {
          onClose()
        })
        .catch((err: any) => {
          setError(err.message)
          console.log(err)
        })
    }
  }

  const renderDroppableArea = (fileType: string) => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          if (fileType === 'WORK_ORDER') {
            handleFileSelect(event.dataTransfer.files[0], 'WORK_ORDER')
          } else if (fileType === 'PMI') {
            handleFileSelect(event.dataTransfer.files[0], 'PMI')
          } else if (fileType === 'INSPECTION') {
            handleFileSelect(event.dataTransfer.files[0], 'INSPECTION')
          }
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={() => handleUploadClick(fileType)}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileType === 'WORK_ORDER' ? fileInputInspectionRef : fileInputPmiRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            if (fileType === 'WORK_ORDER') {
              handleFileSelect(e.target.files[0], 'WORK_ORDER')
            } else if (fileType === 'PMI') {
              handleFileSelect(e.target.files[0], 'PMI')
            } else if (fileType === 'INSPECTION') {
              handleFileSelect(e.target.files[0], 'INSPECTION')
            }
          }
        }}
      />
      {fileType === 'WORK_ORDER' && fileWorkOrder && (
        <>
          <Typography variant="body2">{`Selected file: ${fileWorkOrder.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'PMI' && filePmi && (
        <>
          <Typography variant="body2">{`Selected file: ${filePmi.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
      {fileType === 'INSPECTION' && fileInspection && (
        <>
          <Typography variant="body2">{`Selected file: ${fileInspection.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = (
    index: number,
    field: keyof RecommendedService | string,
    value: string | string[] | number
  ) => {
    // @ts-ignore
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: '',
              types: selectedService ? selectedService.children : [],
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
    )
  }

  const handleServicePartChange = (
    serviceIndex: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === serviceIndex) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                let newValue: any = value

                if (field === 'quantity' || field === 'number') {
                  const inputValue = newValue.replace(/[^0-9]/g, '')
                  if (inputValue === '') {
                    newValue = ''
                  } else {
                    const numberValue = parseInt(inputValue, 10)
                    newValue = numberValue
                  }
                } else if (field === 'price_per_unit') {
                  const input = value.replace(/[^0-9]/g, '')

                  const number = parseInt(input, 10) / 100

                  newValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(number)
                    .slice(1)
                }

                return { ...part, [field]: newValue }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: `service_${recServices.length}`,
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
      labor_hours: 0,
      parts: [],
      imageFiles: [],
      additional_data: null,
      labor_price: 0,
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleAddServicePart = (serviceIndex: number) => {
    setRecServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: [
                ...service.parts,
                {
                  name: '',
                  quantity: 1,
                  price_per_unit: 0,
                  number: 0,
                },
              ],
            }
          : service
      )
    )
  }

  const handleImagesUpload = useCallback((files: File[], index: number) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          return {
            ...service,
            imageFiles: files,
          }
        }
        return service
      })
    )
  }, [])

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setRecServices((prevServices) => {
      const updatedServices = [...prevServices]
      updatedServices[serviceIndex].parts.splice(partIndex, 1)
      return updatedServices
    })
  }

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   changes values in service.additional_data
      //   @ts-ignore
      setRecServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  const [uploadServiceImages] = useUploadRecServiceImagesMutation()

  const handleSubmit = async () => {
    const filteredServices = recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')

    setAlertOpen(false)

    const formattedServices = filteredServices.map((service) => {
      const additionalData = service.additional_data
        ? Object.keys(service.additional_data).reduce((acc, key) => {
            // @ts-ignore
            if (service.additional_data[key] && service.additional_data[key] !== '') {
              // @ts-ignore
              acc[key] = service.additional_data[key]
            }
            const numberFieldsSet = new Set([
              'COLD_CRANKING_AMPS',
              'FACTORY_COLD_CRANKING_AMPS',
              'WARRANTY',
            ])
            if (numberFieldsSet.has(key)) {
              // @ts-ignore
              acc[key] = Number(service.additional_data[key])
            }
            return acc
          }, {})
        : null

      const price =
        service.parts.reduce((total, part) => {
          return total + part.quantity * part.price_per_unit
        }, 0) + service.labor_price

      return {
        service_id: service.type || service.id,
        price: price,
        labor_hours: Number(service.labor_hours),
        parts: service.parts.map((part) => ({
          name: part.name,
          quantity: Number(part.quantity),
          price_per_unit: Number(part.price_per_unit),
          number: Number(part.number),
        })),
        additional_data: additionalData,
      }
    })

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
    }

    try {
      setIsLoading(true)

      await Promise.all(
        recServices.map((service, index) => {
          if (service.imageFiles && service.imageFiles.length > 0) {
            return uploadServiceImages({
              offer_id: offer.id,
              service_id: service.type ?? service.id,
              images: service.imageFiles,
            })
          }
          return Promise.resolve()
        })
      )
      dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
        .then(unwrapResult)
        .then((data: any) => {
          setIsLoading(false)
          onSubmit()
          navigate(0)
        })
    } catch (error) {
      console.error('Error uploading file:', error)
      setUploadStatusWorkOrder('ERROR')
      setUploadStatusInspection('ERROR')
      setUploadStatusPmi('ERROR')
      setIsLoading(false)
    }
  }

  const renderServiceBlocks = () => (
    <>
      {recServices.map((service, index) => {
        return (
          <RecServiceBlock
            service={service}
            recServices={recServices}
            key={`${service.id}_${index}`}
            index={index}
            services={services}
            servicePrices={servicePrices}
            offer={offer}
            handleServiceChange={handleServiceChange}
            handleServicePartChange={handleServicePartChange}
            handleServicePriceChange={handleServicePriceChange}
            handleRemoveService={handleRemoveService}
            handleAddServicePart={handleAddServicePart}
            handleImagesUpload={handleImagesUpload}
            handleRemoveServicePart={handleRemoveServicePart}
            handleAdditionalDataChange={handleAdditionalDataChange}
          />
        )
      })}
    </>
  )

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    const isValid = recServices.every((service) =>
      validateRecommendedServiceFields(services, service, offer.labor_rate)
    )
    setIsFormValid(isValid)
  }, [recServices])

  const renderSummary = () => {
    if (recServices.length === 0) {
      return null
    }

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                marginRight: '8px',
              }}
            >
              Subtotal (Recommended services):
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#DB5D08',
              }}
            >
              {fee && formatPrice(fee)}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '16px !important' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Shop supply fees:
            </Typography>
          </Stack>
          <TextField
            value={shopSupplyFees}
            onChange={handleFeeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Tax:
            </Typography>
          </Stack>
          <TextField
            value={tax}
            onChange={handleTaxChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>

        <Box display="flex" gap={4}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {totalFee && formatPrice(totalFee)}
          </Typography>
        </Box>
      </>
    )
  }

  const renderInspectionFeedbackFile = () => {
    if (!inspectionFeedbackFile) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 2,
          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
          width: showAdditionalServices ? '100%' : '50%',
          ...(!showAdditionalServices && { margin: 'auto' }),
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#FF6600' }}>
          Carma Technician Feedback:
        </Typography>
        <Box
          sx={{
            width: '50%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {inspectionFeedbackFile.name} ({inspectionFeedbackFile.size})
            </Typography>
          </Box>
          <Button
            color="warning"
            href={inspectionFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </Box>
      </Box>
    )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  const renderSummaryAdditionalServices = () => {
    if (offer.services.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
        mt={2}
        mb={2}
      >
        <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Shop supply fees:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
            }}
          >
            {offer.supply_fee && formatPrice(offer.supply_fee)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 1 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Tax:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
            }}
          >
            {offer.tax && formatPrice(offer.tax)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto', mb: 8 }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              marginRight: '8px',
            }}
          >
            Subtotal:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {subtotalRequestedServices && formatPrice(subtotalRequestedServices)}
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderAdditionalServicesBlock = () => {
    if (!showAdditionalServices) return null

    return (
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, mt: 3, color: '#FF6600' }}>
            Additional recommended services
          </Typography>

          <Box>
            {' '}
            <Button
              variant="contained"
              onClick={() => setShowAdditionalServices(false)}
              sx={{
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
                backgroundColor: '#fff',
                mt: 3,
                color: '#000',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                '&:active': {
                  backgroundColor: '#grey',
                  color: '#fff',
                },
              }}
            >
              <Close /> <Typography sx={{ ml: 2 }}> Remove additional services</Typography>
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {' '}
            {!isPmi && (
              <>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Vehicle inspection (optional)
                </Typography>
                {renderDroppableArea('INSPECTION')}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            {/*<Typography variant="h6" sx={{ fontWeight: 'bold' }}>*/}
            {/*  Work order authorization report for additional recommended services*/}
            {/*</Typography>*/}
            {/*<Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>*/}
            {/*  Includes services required to resolve issues identified during vehicle inspection.*/}
            {/*</Typography>*/}
            {/*{renderDroppableArea('WORK_ORDER')}*/}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Services
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
              List all the services which customer’s vehicle needs.
            </Typography>
            {renderServiceBlocks()}
            <Button
              onClick={handleAddService}
              variant="outlined"
              color="warning"
              sx={{ mt: 1, textTransform: 'none' }}
            >
              <AddIcon />
              <span style={{ margin: '4px', paddingLeft: '10px', color: '#FF6600' }}>
                Add service
              </span>
            </Button>
            {renderSummary()}
            {recServices.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {' '}
                <Button
                  variant="contained"
                  color="warning"
                  disabled={
                    (isPmi && !filePmi) ||
                    (isPmi && uploadStatusPmi !== 'IDLE' && uploadStatusPmi !== 'SUCCESS') ||
                    isLoading ||
                    !isFormValid
                  }
                  onClick={handleUpload}
                  sx={{
                    width: 'fit-content',
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                  }}
                >
                  Send for approval
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Stack>
    )
  }

  const renderRequestedServicesBlock = () => {
    return (
      <Stack
        sx={{
          width: showAdditionalServices ? '85%' : '100%',
        }}
      >
        {renderInspectionFeedbackFile()}
        <Stack
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: showAdditionalServices ? 'none' : 'center',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
            Requested services
          </Typography>

          {offer.services
            .filter((service) => service.is_approved)
            .map((service, index) => (
              <Box
                sx={{
                  width: showAdditionalServices ? '100%' : '50%',
                }}
                key={`${service.id}_${service.type}_${index}`}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                    mt: '6px',
                  }}
                >
                  <Stack spacing={1} sx={{ flex: showAdditionalServices ? '' : '1' }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontFamily: APP_FONT,
                        fontWeight: '600',
                        color: '#393A3D',
                        mt: 1,
                      }}
                    >
                      {service.service.parent.parent_id
                        ? service.service.parent.name
                        : service.service.name}
                    </Typography>
                    {Array.isArray(service?.additional_data?.TIRE_POSITIONS) &&
                    service.additional_data.TIRE_POSITIONS.length > 0 ? (
                      <>
                        <AdditionalDataInfo
                          label="Tires need to be replaced"
                          value={service.additional_data.TIRE_POSITIONS.join(', ')}
                        />
                        <AdditionalDataInfo label="Tire size" value={tireInfo} />
                        <AdditionalDataInfo
                          label="Preferred tire brand"
                          value={
                            typeof service.additional_data?.TIRE_BRAND === 'object'
                              ? service.additional_data?.TIRE_BRAND?.name || ''
                              : service.additional_data?.TIRE_BRAND || ''
                          }
                        />
                      </>
                    ) : null}

                    <Typography variant="body2" color="textSecondary">
                      {service.service.parent.parent_id && service.service.name}
                    </Typography>
                    {service.service.required_data?.includes('PMI') && (
                      <a
                        href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
                        download
                        target="_blank"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: '#FF6600',
                        }}
                        rel="noreferrer"
                      >
                        <Typography
                          fontFamily={APP_FONT}
                          fontSize={16}
                          mr={1}
                          style={{
                            fontSize: '16px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#FF6600',
                          }}
                        >
                          PMI Download Link
                        </Typography>
                        <DownloadIcon color="#FF6600" />
                      </a>
                    )}
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#344054',
                    }}
                  >
                    {formatPrice(service.price)}
                  </Typography>
                </Box>
                {index < offer.services.length && <Divider />}
              </Box>
            ))}
          {isPmi && (
            <>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                PMI report
              </Typography>
              <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                Includes the results of the Preventative Maintenance Inspection (PMI).
              </Typography>
              {renderDroppableArea('PMI')}
            </>
          )}
          {showAdditionalServices && renderSummaryAdditionalServices()}
        </Stack>
      </Stack>
    )
  }

  const isPmi = offer.services.find((service) => service.service.required_data?.includes('PMI'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '90vh',
        },
      }}
    >
      {isLoading ? (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            height: '120vh',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
          }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-30px',
              marginLeft: '-30px',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <Box>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'WORK_ORDER',
              }}
            />
          </Box>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent style={{ overflow: 'auto', maxHeight: '80vh' }}>
            <Stack
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: showAdditionalServices ? 'row' : 'column',
                justifyContent: 'space-between',
              }}
            >
              {showAdditionalServices ? (
                renderRequestedServicesBlock()
              ) : (
                <>
                  {renderRequestedServicesBlock()}
                  <Stack sx={{ width: '100%' }}>
                    <>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '700',
                          textAlign: 'center',
                          mb: 3,
                          mt: 3,
                          color: '#393A3D',
                        }}
                      >
                        Does this vehicle need any other services? If so, use the button below to
                        add them.
                      </Typography>

                      <Stack sx={{ alignItems: 'center' }}>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => setShowAdditionalServices(true)}
                          sx={{
                            width: 'fit-content',
                            borderRadius: '50px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            paddingLeft: '32px',
                            paddingRight: '32px',
                            paddingY: '8px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <PlusIcon style={{ color: '#fff  !important', marginRight: '8px' }} />
                          <span>Recommend additional services</span>
                        </Button>

                        <Button
                          variant="outlined"
                          disabled={
                            (isPmi && !filePmi) ||
                            (uploadStatusWorkOrder !== 'IDLE' &&
                              uploadStatusWorkOrder !== 'SUCCESS')
                          }
                          color="warning"
                          onClick={async () => {
                            await handleUploadInspection()
                            setInspectionWithoutServicesModalOpen(true)
                          }}
                          sx={{
                            width: 'fit-content',
                            borderRadius: '50px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            paddingLeft: '32px',
                            paddingRight: '32px',
                            paddingY: '8px',
                            mt: 4,
                            mb: 2,
                            borderColor: '#FF6600',
                            color: '#FF6600',
                            borderWidth: '2px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          Continue without recommending any services
                          <ArrowForwardIcon sx={{ marginLeft: '8px' }} />{' '}
                        </Button>
                      </Stack>
                    </>
                  </Stack>
                </>
              )}

              {showAdditionalServices && (
                <Stack sx={{ width: '100%' }}>
                  <Divider orientation="vertical" flexItem />
                  <div style={{ width: '100%', paddingLeft: '20px' }}>
                    {renderAdditionalServicesBlock()}
                  </div>
                </Stack>
              )}
            </Stack>
          </DialogContent>
          <InspectionWithoutServicesModal
            isOpen={isInspectionWithoutServicesModalOpen}
            setIsOpen={setInspectionWithoutServicesModalOpen}
            offer={offer}
            onClose={() => setInspectionWithoutServicesModalOpen(false)}
            onConfirmWithoutService={() => {
              onConfirmWithoutService()
            }}
          />
          <ErrorHandler error={error} clearError={clearError} />
        </>
      )}
    </Dialog>
  )
}

export default InspectionUploadModal
