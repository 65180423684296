export const listValidationsArr = {
  "items": [
    {
      "key": "TIRE_MODEL",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [],
      "validation": null
    },
    {
      "key": "TIRE_BRAND",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [],
      "validation": null
    },
    {
      "key": "COLD_CRANKING_AMPS",
      "value_type": "INT",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [],
      "validation": {
        "max": 2000,
        "min": 0
      }
    },
    {
      "key": "FACTORY_COLD_CRANKING_AMPS",
      "value_type": "INT",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [],
      "validation": {
        "max": 2000,
        "min": 0
      }
    },
    {
      "key": "WARRANTY",
      "value_type": "INT",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [],
      "validation": null
    },
    {
      "key": "LF_PAD",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1 mm",
        "2 mm",
        "3 mm",
        "4 mm",
        "5 mm",
        "6 mm",
        "7 mm",
        "8 mm",
        "9 mm",
        "10 mm",
        "11 mm",
        "12 mm",
        "13 mm",
        "14 mm",
        "15 mm",
        "16 mm",
        "17 mm",
        "18 mm",
        "19 mm",
        "20 mm",
        "21 mm",
        "22 mm",
        "23 mm",
        "24 mm",
        "25 mm",
        "26 mm",
        "27 mm",
        "28 mm",
        "29 mm",
        "30 mm",
        "31 mm",
        "32 mm"
      ],
      "validation": null
    },
    {
      "key": "RF_PAD",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1 mm",
        "2 mm",
        "3 mm",
        "4 mm",
        "5 mm",
        "6 mm",
        "7 mm",
        "8 mm",
        "9 mm",
        "10 mm",
        "11 mm",
        "12 mm",
        "13 mm",
        "14 mm",
        "15 mm",
        "16 mm",
        "17 mm",
        "18 mm",
        "19 mm",
        "20 mm",
        "21 mm",
        "22 mm",
        "23 mm",
        "24 mm",
        "25 mm",
        "26 mm",
        "27 mm",
        "28 mm",
        "29 mm",
        "30 mm",
        "31 mm",
        "32 mm"
      ],
      "validation": null
    },
    {
      "key": "LR_PAD",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1 mm",
        "2 mm",
        "3 mm",
        "4 mm",
        "5 mm",
        "6 mm",
        "7 mm",
        "8 mm",
        "9 mm",
        "10 mm",
        "11 mm",
        "12 mm",
        "13 mm",
        "14 mm",
        "15 mm",
        "16 mm",
        "17 mm",
        "18 mm",
        "19 mm",
        "20 mm",
        "21 mm",
        "22 mm",
        "23 mm",
        "24 mm",
        "25 mm",
        "26 mm",
        "27 mm",
        "28 mm",
        "29 mm",
        "30 mm",
        "31 mm",
        "32 mm"
      ],
      "validation": null
    },
    {
      "key": "RR_PAD",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1 mm",
        "2 mm",
        "3 mm",
        "4 mm",
        "5 mm",
        "6 mm",
        "7 mm",
        "8 mm",
        "9 mm",
        "10 mm",
        "11 mm",
        "12 mm",
        "13 mm",
        "14 mm",
        "15 mm",
        "16 mm",
        "17 mm",
        "18 mm",
        "19 mm",
        "20 mm",
        "21 mm",
        "22 mm",
        "23 mm",
        "24 mm",
        "25 mm",
        "26 mm",
        "27 mm",
        "28 mm",
        "29 mm",
        "30 mm",
        "31 mm",
        "32 mm"
      ],
      "validation": null
    },
    {
      "key": "LF_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "RF_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "LR_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "LR_INSIDE_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "RR_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "RR_INSIDE_MEASUREMENT",
      "value_type": "STRING",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": null,
      "permitted_values": [
        "1/32 in",
        "2/32 in",
        "3/32 in",
        "4/32 in",
        "5/32 in",
        "6/32 in",
        "7/32 in",
        "8/32 in",
        "9/32 in",
        "10/32 in",
        "11/32 in",
        "12/32 in",
        "13/32 in",
        "14/32 in",
        "15/32 in",
        "16/32 in",
        "17/32 in",
        "18/32 in",
        "19/32 in",
        "20/32 in",
        "21/32 in",
        "22/32 in",
        "23/32 in",
        "24/32 in",
        "25/32 in",
        "26/32 in",
        "27/32 in",
        "28/32 in",
        "29/32 in",
        "30/32 in",
        "31/32 in",
        "32/32 in"
      ],
      "validation": null
    },
    {
      "key": "TIRE_POSITIONS",
      "value_type": "STRING_ARRAY",
      "request_required_data": null,
      "offer_required_data": null,
      "recommend_required_data": {
        "LF": "LF_MEASUREMENT",
        "LR": "LR_MEASUREMENT",
        "RF": "RF_MEASUREMENT",
        "RR": "RR_MEASUREMENT",
        "LR Inside": "LR_INSIDE_MEASUREMENT",
        "RR Inside": "RR_INSIDE_MEASUREMENT"
      },
      "permitted_values": [
        "LF",
        "RF",
        "LR",
        "LR Inside",
        "RR",
        "RR Inside"
      ],
      "validation": null
    }
  ]
}

export const listValidations = {
  "TIRE_MODEL": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [],
    "validation": null
  },
  "TIRE_BRAND": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [],
    "validation": null
  },
  "COLD_CRANKING_AMPS": {
    "value_type": "INT",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [],
    "validation": {
      "max": 2000,
      "min": 0
    }
  },
  "FACTORY_COLD_CRANKING_AMPS": {
    "value_type": "INT",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [],
    "validation": {
      "max": 2000,
      "min": 0
    }
  },
  "WARRANTY": {
    "value_type": "INT",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [],
    "validation": null
  },
  "LF_PAD": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1 mm",
      "2 mm",
      "3 mm",
      "4 mm",
      "5 mm",
      "6 mm",
      "7 mm",
      "8 mm",
      "9 mm",
      "10 mm",
      "11 mm",
      "12 mm",
      "13 mm",
      "14 mm",
      "15 mm",
      "16 mm",
      "17 mm",
      "18 mm",
      "19 mm",
      "20 mm",
      "21 mm",
      "22 mm",
      "23 mm",
      "24 mm",
      "25 mm",
      "26 mm",
      "27 mm",
      "28 mm",
      "29 mm",
      "30 mm",
      "31 mm",
      "32 mm"
    ],
    "validation": null
  },
  "RF_PAD": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1 mm",
      "2 mm",
      "3 mm",
      "4 mm",
      "5 mm",
      "6 mm",
      "7 mm",
      "8 mm",
      "9 mm",
      "10 mm",
      "11 mm",
      "12 mm",
      "13 mm",
      "14 mm",
      "15 mm",
      "16 mm",
      "17 mm",
      "18 mm",
      "19 mm",
      "20 mm",
      "21 mm",
      "22 mm",
      "23 mm",
      "24 mm",
      "25 mm",
      "26 mm",
      "27 mm",
      "28 mm",
      "29 mm",
      "30 mm",
      "31 mm",
      "32 mm"
    ],
    "validation": null
  },
  "LR_PAD": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1 mm",
      "2 mm",
      "3 mm",
      "4 mm",
      "5 mm",
      "6 mm",
      "7 mm",
      "8 mm",
      "9 mm",
      "10 mm",
      "11 mm",
      "12 mm",
      "13 mm",
      "14 mm",
      "15 mm",
      "16 mm",
      "17 mm",
      "18 mm",
      "19 mm",
      "20 mm",
      "21 mm",
      "22 mm",
      "23 mm",
      "24 mm",
      "25 mm",
      "26 mm",
      "27 mm",
      "28 mm",
      "29 mm",
      "30 mm",
      "31 mm",
      "32 mm"
    ],
    "validation": null
  },
  "RR_PAD": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1 mm",
      "2 mm",
      "3 mm",
      "4 mm",
      "5 mm",
      "6 mm",
      "7 mm",
      "8 mm",
      "9 mm",
      "10 mm",
      "11 mm",
      "12 mm",
      "13 mm",
      "14 mm",
      "15 mm",
      "16 mm",
      "17 mm",
      "18 mm",
      "19 mm",
      "20 mm",
      "21 mm",
      "22 mm",
      "23 mm",
      "24 mm",
      "25 mm",
      "26 mm",
      "27 mm",
      "28 mm",
      "29 mm",
      "30 mm",
      "31 mm",
      "32 mm"
    ],
    "validation": null
  },
  "LF_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "RF_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "LR_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "LR_INSIDE_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "RR_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "RR_INSIDE_MEASUREMENT": {
    "value_type": "STRING",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": null,
    "permitted_values": [
      "1/32 in",
      "2/32 in",
      "3/32 in",
      "4/32 in",
      "5/32 in",
      "6/32 in",
      "7/32 in",
      "8/32 in",
      "9/32 in",
      "10/32 in",
      "11/32 in",
      "12/32 in",
      "13/32 in",
      "14/32 in",
      "15/32 in",
      "16/32 in",
      "17/32 in",
      "18/32 in",
      "19/32 in",
      "20/32 in",
      "21/32 in",
      "22/32 in",
      "23/32 in",
      "24/32 in",
      "25/32 in",
      "26/32 in",
      "27/32 in",
      "28/32 in",
      "29/32 in",
      "30/32 in",
      "31/32 in",
      "32/32 in"
    ],
    "validation": null
  },
  "TIRE_POSITIONS": {
    "value_type": "STRING_ARRAY",
    "request_required_data": null,
    "offer_required_data": null,
    "recommend_required_data": {
      "LF": "LF_MEASUREMENT",
      "LR": "LR_MEASUREMENT",
      "RF": "RF_MEASUREMENT",
      "RR": "RR_MEASUREMENT",
      "LR Inside": "LR_INSIDE_MEASUREMENT",
      "RR Inside": "RR_INSIDE_MEASUREMENT"
    },
    "permitted_values": [
      "LF",
      "RF",
      "LR",
      "LR Inside",
      "RR",
      "RR Inside"
    ],
    "validation": null
  }
}

export type ServiceValidationKey = "TIRE_MODEL" | "TIRE_BRAND" | "COLD_CRANKING_AMPS" | "FACTORY_COLD_CRANKING_AMPS" | "WARRANTY" | "LF_PAD" | "RF_PAD" | "LR_PAD" | "RR_PAD" | "LF_MEASUREMENT" | "RF_MEASUREMENT" | "LR_MEASUREMENT" | "LR_INSIDE_MEASUREMENT" | "RR_MEASUREMENT" | "RR_INSIDE_MEASUREMENT" | "TIRE_POSITIONS"
