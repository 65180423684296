import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'

interface ErrorHandlerProps {
  error: any
  clearError: () => void
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ error, clearError }) => {
  const [open, setOpen] = useState<boolean>(!!error)

  useEffect(() => {
    if (error) {
      setOpen(true)
      const timer = setTimeout(() => {
        setOpen(false)
        clearError()
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [error, clearError])

  const handleClose = () => {
    setOpen(false)
    clearError()
  }
  let errorMessage
  if (error && typeof error.message === 'string') {
    errorMessage = error.message
  } else if (typeof error === 'string') {
    errorMessage = error
  } else {
    if (error && error.error && typeof error.error === 'string') {
      errorMessage = error.error
    } else {
      errorMessage = JSON.stringify(error, null, 1)
    }
  }

  return (
    <Snackbar
      className="custom-snackbar"
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        <>{errorMessage}</>
      </Alert>
    </Snackbar>
  )
}

export default ErrorHandler
