import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { AvatarPickerProps } from './types'
import { compressImage } from '../../../utils/helper'

const Wrapper = styled.div`
  position: relative;
  width: min-content;
  cursor: pointer;
  height: 140px;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const AvatarStyled = styled(Avatar)`
  & {
    border: solid 1px #e0e0e0;
    border-radius: 10px;
  }

  height: 100px;
  width: 100px;
  & > * {
    border: none;
  }
`

export const AvatarPicker = (props: AvatarPickerProps) => {
  const { onFilePicked, fileTypes, src, size, round } = props
  const [url, setUrl] = useState('')
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const compressedFile = await compressImage(e.target.files[0])
        if (compressedFile) {
          setUrl(compressedFile.url)
          onFilePicked(compressedFile)
        }
      } catch (error) {
        console.error('Image compression error:', error)
      }
    }
  }

  const accept = fileTypes?.join(', ').trim() || ''

  return (
    <Wrapper>
      <AvatarStyled color="unset" round={round} src={url.length ? url : src} size={size} />
      <Input type="file" onChange={onChange} accept={accept} />
    </Wrapper>
  )
}
