import { Button, Modal, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { APP_FONT } from '../../constants/app_font'
import ModalTableHeadShort from '../../pages/dashboard/tables/modals/components/table_head_short'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F5F5F5',
  width: 700,
  maxHeight: '90vh',
  overflowY: 'auto',
}

export default function VinModal(props: any) {
  const inputRef = React.useRef<any>(null)
  const { isOpen, setIsOpen, updateVehicle, offer } = props
  const [vin, setVin] = React.useState<any>('')
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<any>('')

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }, [])

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <Box sx={style}>
        <ModalTableHeadShort
          onHide={() => setIsOpen(!isOpen)}
          service_request={{
            slug: offer.slug,
            vehicle: offer.vehicle ?? offer.consumer_request?.vehicle,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            mb: 5,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
              fontFamily: APP_FONT,
              color: '#FF6600',
              mr: 4,
            }}
          >
            In order to mark this vehicle as <br />
            arrived, please enter the VIN:
          </Typography>

          <Box sx={{ p: 0, mr: 2, ml: -2 }}>
            <TextField
              inputRef={inputRef}
              inputProps={{ maxLength: 17 }}
              sx={{ mt: '20px' }}
              fullWidth
              color="warning"
              label="VIN"
              variant="outlined"
              focused
              onChange={(e) => {
                const value = e.target.value.toUpperCase()
                if (value.length !== 17) {
                  setError(true)
                  setErrorMessage('VIN should be 17 characters')
                } else {
                  setError(false)
                  setErrorMessage('')
                }
                setVin(value)
              }}
              value={vin}
            />
            {error && (
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 500,
                  fontFamily: APP_FONT,
                  color: 'red',
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
          <Box
            component="span"
            display="flex"
            justifyContent="right"
            marginTop={'22px'}
            sx={{ mr: 3 }}
          >
            <Button
              onClick={() => setIsOpen(false)}
              sx={{
                width: 75,
                height: 30,
                borderRadius: '20px',
                mr: 2,
              }}
              color="inherit"
            >
              <Typography
                sx={{
                  color: '#FF6600',
                  fontSize: '13px',
                  fontFamily: APP_FONT,
                  fontWeight: 700,
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={() => {
                updateVehicle(vin, null)
                setIsOpen(false)
              }}
              sx={{ width: 68, height: 30, borderRadius: '20px' }}
              variant="contained"
              color="warning"
              disabled={error || vin === ''}
            >
              <Typography
                sx={{
                  fontSize: '13px',
                  fontFamily: APP_FONT,
                  fontWeight: 700,
                }}
              >
                Enter
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
