import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { serviceApi } from './api/serviceApi/serviceApi';
import { rtkQueryErrorLogger } from './errorHandler';
import userReducer from "./reducers/userSlice";
import serviceRequestReducer from "./reducers/serviceRequestSlice";
import offerReducer from "./reducers/offerSlice";
import accountSettingsReducer from "./reducers/accountSettingsSlice";
import vehicleReducer from "./reducers/vehicleSlice";
import { vehicleApi } from "./api/vehicleApi/vehicleApi";

const rootReducer = {
  [serviceApi.reducerPath]: serviceApi.reducer,
  [vehicleApi.reducerPath]: vehicleApi.reducer,
  user: userReducer,
  service_request: serviceRequestReducer,
  offer: offerReducer,
  account_settings: accountSettingsReducer,
  vehicle: vehicleReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      serviceApi.middleware,
      vehicleApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
