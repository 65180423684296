import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_NEST_JS_API,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const apiCall = async <T = any>(
  url: string,
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  data?: any,
  params?: any
): Promise<T> => {
  const userToken = localStorage.getItem('userToken')
  try {
    const response = await api({
      url,
      method,
      data,
      params,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    return response.data
  } catch (error: any) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location.href = '/'
    }
    throw error
  }
}
