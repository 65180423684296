import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiCall } from '../../apis/api'
import { ActionTypes } from '../constants/action-types'
import { handleErrors } from '../../utils/helper'

export const listImages = createAsyncThunk(
  ActionTypes.LIST_IMAGES,
  async (providerBranchId: string, { rejectWithValue }) => {
    try {
      const response = await apiCall(`provider/branch/${providerBranchId}/images`, 'get')
      return response
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const uploadImages = createAsyncThunk(
  ActionTypes.UPLOAD_IMAGES,
  async (params: { providerBranchId: string; files: File[] }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      Array.from(params.files).forEach((file) => {
        formData.append('images', file)
      })

      const response = await apiCall(
        `provider/branch/${params.providerBranchId}/images`,
        'post',
        formData
      )
      return response.data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const updateImagesOrder = createAsyncThunk(
  ActionTypes.UPDATE_IMAGES,
  async (params: { providerBranchId: number; items: any[] }, { rejectWithValue }) => {
    try {
      const response = await apiCall(`provider/branch/${params.providerBranchId}/images`, 'put', {
        items: params.items,
      })
      return response.data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const deleteImages = createAsyncThunk(
  ActionTypes.DELETE_IMAGES,
  async (params: { providerBranchId: string; imageIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/branch/${params.providerBranchId}/images`,
        'delete',
        undefined,
        { provider_branch_image_ids: params.imageIds }
      )
      return response.data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)

export const markAsCover = createAsyncThunk(
  'images/markAsCover',
  async (params: { providerBranchId: string; imageId: string }, { rejectWithValue }) => {
    try {
      const response = await apiCall(
        `provider/branch/${params.providerBranchId}/images/${params.imageId}`,
        'patch'
      )
      return response.data
    } catch (error: any) {
      return handleErrors(error, rejectWithValue)
    }
  }
)
