import { useEffect, useState } from 'react'
import moment from 'moment-timezone'

function useLocalTimezoneDates<T, K extends keyof T>(data: T[] | null, dateKeys: K[]): T[] {
  const [localData, setLocalData] = useState<T[]>([])

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        const timezone = localStorage.getItem('timezone')

        if (timezone && data) {
          const convertedData = data.map((item) => {
            const newItem = { ...item }
            dateKeys.forEach((key) => {
              const dateValue = newItem[key]
              if (dateValue && typeof dateValue === 'string') {
                newItem[key] = moment(dateValue)
                  .tz(timezone)
                  .format('YYYY-MM-DD HH:mm:ss') as unknown as T[K]
              }
            })
            return newItem
          })

          if (JSON.stringify(localData) !== JSON.stringify(convertedData)) {
            setLocalData(convertedData)
          }
        }
      } catch (error) {
        console.error('Error fetching timezone:', error)
      }
    }

    fetchTimezone()
  }, [data, dateKeys, localData])

  return localData
}

export default useLocalTimezoneDates
