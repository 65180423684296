import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { RootState } from './store';

export type Pagination = {
  page: number;
  limit?: number;
};

export const baseUrl = process.env.REACT_APP_NEST_JS_API;

export const baseQuery = () =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('userToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
