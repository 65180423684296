import React from 'react'
import { TextField } from '@mui/material'

type TireModelInputProps = {
  value: string
  onChange: (value: string) => void
}

export const TireModelInput = ({ value, onChange }: TireModelInputProps) => {
  return (
    <TextField
      fullWidth
      label="Model"
      placeholder="Enter tire model"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        style: {
          background: '#fff',
          width: '100%',
        },
      }}
    />
  )
}
