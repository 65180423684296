import React, { memo, useCallback, useState } from 'react';
import styled from "styled-components";
import { Button, Dialog, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SuccessImg from "../../../../../assets/images/success.png";
import QRCode from "react-qr-code";
import {
  useDeleteRecServiceImageMutation,
  useListRecServiceImagesQuery
} from "../../../../../redux/api/serviceApi/serviceApi";
import { OfferServiceImage } from "../../../../../redux/api/serviceApi/serviceApi.types";
import { APP_FONT } from "../../../../../constants/app_font";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid #ffc9b3;
    border-radius: 8px;
    padding: 12px 8px;
`;

type RecServicePhotoUploadProps = {
  serviceName: string;
  onUpload: (files: File[]) => void;
  serviceId: string;
  offerId: string;
}

export const RecServicePhotoUpload = (props: RecServicePhotoUploadProps) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const qrCodeValue = `${process.env.REACT_APP_CUSTOMER_APP_URL}/provider-qrUpload?serviceId=${props.serviceId}&offerId=${props.offerId}`;

  const { data, refetch } = useListRecServiceImagesQuery({
    service_id: props.serviceId,
    offer_id: props.offerId,
  })

  const [deleteImage] = useDeleteRecServiceImageMutation();

  const onDelete = async (imageId: string) => {
    try {
      await deleteImage({
        service_id: props.serviceId,
        offer_id: props.offerId,
        image_id: imageId,
      }).unwrap();
    } catch (e) {
      console.error(e);
    }
  }

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleUploadClicked = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = 'image/*';
    input.addEventListener('change', (e) => {
      // @ts-ignore
      const files = Array.from((e.target as HTMLInputElement).files);
      setFiles(files);
    });
    document.body.appendChild(input);
    input.click();
  }, []);

  const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    // accept only PNG, JPEG, JPG file types

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file' &&
          e.dataTransfer.items[i].type.match('^image/')) {
          continue;
        }
        return;
      }
    }

    const files = Array.from(e.dataTransfer.files);
    setFiles(files);
  }, []);

  const onRemove = useCallback((index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  }, [files]);

  const onSubmit = () => {
    props.onUpload(files);
    setIsSubmitted(true);
    onClose();
  }

  const hasUploadedImages = (data?.items && data.items?.length > 0) || files.length > 0;

  return (
    <>
      <Wrapper onClick={onOpen} style={{
        cursor: 'pointer',
        marginBottom: '20px'
      }}>
        {isSubmitted ? <img style={{
          height: 24,
          width: 24,
        }} src={SuccessImg} alt="uploaded" /> : null}
        <div>
          <div style={{
            fontWeight: 600,
            fontSize: 16,
            fontFamily: APP_FONT,
          }}>Photo Upload Required
          </div>
          <div style={{
            fontSize: 12,
            fontFamily: APP_FONT,
          }}>Please upload pictures of the issue.
          </div>
        </div>
        <div
          onClick={refetch}
          style={{
          fontFamily: APP_FONT,
          textDecoration: 'underline',
          color: '#ff6600',
          fontWeight: 600,
          fontSize: 14,
        }}>{isSubmitted ? 'See uploaded files' : 'Upload images'}
        </div>
      </Wrapper>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            padding: 20,
            maxHeight: '90vh',
          }
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
          <div>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, fontFamily: APP_FONT, }}>
              Photo upload
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, fontFamily: APP_FONT, }}>
              Please upload pictures of the issue.
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <DialogContent style={{ overflow: 'auto', maxHeight: '80vh' }}>
          <Typography variant="body2" sx={{ mb: 1, fontFamily: APP_FONT, }}>
            Service requiring photo upload:
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: '#ff6600', fontWeight: 'bold', fontFamily: APP_FONT, }}>
            {props.serviceName}
          </Typography>
          <div style={{
            display: 'flex',
          }}>
            <div
              onDragOver={(event) => event.preventDefault()}
              onDrop={onDrop}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px dashed #ff6600',
                borderRadius: 8,
                padding: 20,
                marginTop: 20,
                flex: 1
              }}>
              <Typography variant="body1" sx={{ mb: 1, color: '#ff6600', fontWeight: 'bold' }}>
                <div onClick={handleUploadClicked}>Click to upload</div>
                <Typography variant="body1" sx={{ mb: 1 }}> or drag and drop PNG, JPEG, JPG (max 10MB each)</Typography>
              </Typography>
            </div>
            <div>
              {props.serviceId && props.offerId ? <QRCode value={qrCodeValue} size={150} style={{
                marginLeft: 20,
              }} /> : null}
            </div>
          </div>
            <div>
              <Divider sx={{ mt: 2, mb: 2 }} />
              {hasUploadedImages ? (<Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold', marginTop: '24px' }}>
                Uploaded files:
              </Typography>) : null}
              {data?.items.map((file, index) => (
                <FileItem
                  key={file.id}
                  img={file}
                  onRemove={() => onDelete(file.id)}
                />
              ))}
              {files.map((file, index) => (
                <FileItem
                  key={file.name}
                  file={file}
                  onRemove={() => onRemove(index)}
                />
              ))}
            </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
          }}>
            <Button
              variant="contained"
              color="warning"
              onClick={onSubmit}
              sx={{
                margin: '0 auto',
                width: 'fit-content',
                borderRadius: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                paddingLeft: '32px',
                paddingRight: '32px',
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const FileItem = memo(({ file, onRemove, img }: { file?: File, img?: OfferServiceImage, onRemove: () => void }) => {
  const url = file ? URL.createObjectURL(file) : img?.url;
  return (
    <div style={{
      display: 'flex',
      border: '1px solid #eaecf0',
      borderRadius: 12,
      padding: 12,
    }}>
      <img
        src={url}
        alt={file?.name || img?.name}
        style={{
          width: 90,
          height: 60,
          objectFit: 'cover',
          borderRadius: 12,
        }}
      />
      <div style={{
        flex: 1,
        marginLeft: 12,
      }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          {file?.name || img?.name}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {Math.round((file?.size || img?.size) as number / 1024)} KB
        </Typography>
      </div>
      <IconButton
        aria-label="close"
        color="inherit"
        size="medium"
        onClick={onRemove}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
});
