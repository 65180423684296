import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';

// Define a custom type for the action payload
interface RejectedActionPayload {
  status: number;
  data?: {
    error?: string | { message: string }[];
    message?: string | { error: string }[];
  };
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as RejectedActionPayload;
    }

    return next(action);
  };
